import { action } from 'typesafe-actions'
import { Specialization } from '../../../types/Specialization'
import { types } from '../index'

/**
 * It returns an action object with a type of `LIST_SPECIALIZATIONS_REQUEST`
 */
export const getListSpecializationRequest = () =>
  action(types.SpecializationTypes.LIST_SPECIALIZATIONS_REQUEST)

/**
 * It returns an action object with the type of `LIST_SPECIALIZATIONS_SUCCESS` and
 * the data of the specialization list
 * @param {Specialization[]} data - Specialization[] - the data that will be passed
 * to the reducer
 */
export const getListSpecializationSuccess = (data: Specialization[]) =>
  action(types.SpecializationTypes.LIST_SPECIALIZATIONS_SUCCESS, { data })

/**
 * It returns an action with the type `LIST_SPECIALIZATIONS_FAILURE` and the error
 * object
 * @param {any} error - any
 */
export const getListSpecializationFailure = (error: any) =>
  action(types.SpecializationTypes.LIST_SPECIALIZATIONS_FAILURE, { error })
