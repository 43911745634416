import { action } from 'typesafe-actions'
import { Modality } from '../../../types/Modality'
import { types } from '../index'

/**
 * It returns an action object with a type of LIST_MODALITY_REQUEST
 */
export const getListModalityRequest = () => action(types.ModalityTypes.LIST_MODALITY_REQUEST)

/**
 * It returns an action with the type `types.ModalityTypes.LIST_MODALITY_SUCCESS`
 * and the data `data`
 * @param {Modality[]} data - Modality[] - this is the data that will be passed to
 * the reducer
 */
export const getListModalitySuccess = (data: Modality[]) =>
  action(types.ModalityTypes.LIST_MODALITY_SUCCESS, { data })

/**
 * It returns an action object with the type of LIST_MODALITY_FAILURE and the error
 * object
 * @param {any} error - any
 */
export const getListModalityFailure = (error: any) =>
  action(types.ModalityTypes.LIST_MODALITY_FAILURE, { error })
