import { action } from 'typesafe-actions'
import { Order } from './types'
import { types } from '../index'
import { Page } from '../../../types/Page'
import { Error } from '../../../types/Error'
import { MyScheduleFilter } from '../../../types/MySchedule'
import { cancelPendingOrder } from './sagas'

/**
 * It returns an action object with the type `ACCEPT_ORDER_REQUEST` and the payload
 * `{ orderId }`
 * @param {string} orderId - The id of the order to accept
 * @param mySchedule
 */
export const acceptOrderRequest = (orderId: string, mySchedule?: MyScheduleFilter) =>
  action(types.OrdersTypes.ACCEPT_ORDER_REQUEST, { orderId, mySchedule })

/**
 * It creates an action with the type ACCEPT_ORDER_SUCCESS and the orderId and
 * order as the payload
 * @param {string} orderId - The id of the order that was accepted.
 * @param {Order} order - Order
 */
export const acceptOrderSuccess = (orderId: string, order: Order) =>
  action(types.OrdersTypes.ACCEPT_ORDER_SUCCESS, {
    orderId,
    order,
  })

/**
 * It returns an action with the type ACCEPT_ORDER_FAILURE and the error that was
 * passed in
 * @param {any} error - any
 */
export const acceptOrderFailure = (error: any) =>
  action(types.OrdersTypes.ACCEPT_ORDER_FAILURE, {
    error,
  })

/**
 * It returns an action object with the type `DENY_ORDER_REQUEST` and the orderId
 * as the payload
 * @param {string} orderId - The id of the order to deny
 * @param mySchedule
 */
export const denyOrderRequest = (orderId: string, mySchedule?: MyScheduleFilter) =>
  action(types.OrdersTypes.DENY_ORDER_REQUEST, { orderId, mySchedule })

/**
 * It returns an action object with the type `DENY_ORDER_SUCCESS` and the orderId
 * and order as payload
 * @param {string} orderId - The id of the order that was denied.
 * @param {Order} order - Order - this is the order that was denied
 */
export const denyOrderSuccess = (orderId: string, order: Order) =>
  action(types.OrdersTypes.DENY_ORDER_SUCCESS, {
    orderId,
    order,
  })

/**
 * It returns an action object with the type of DENY_ORDER_FAILURE and the error
 * object
 * @param {any} error - any
 */
export const denyOrderFailure = (error: any) =>
  action(types.OrdersTypes.DENY_ORDER_FAILURE, {
    error,
  })

/**
 * It returns an action object with the type CANCEL_ORDER_REQUEST and the orderId
 * as the payload
 * @param {string} orderId - The ID of the order to cancel.
 * @param mySchedule
 */
export const cancelOrderRequest = (orderId: string, mySchedule?: MyScheduleFilter) =>
  action(types.OrdersTypes.CANCEL_ORDER_REQUEST, { orderId, mySchedule })

/**
 * It returns an action object with the type CANCEL_ORDER_SUCCESS and the orderId
 * and order as payload
 * @param {string} orderId - The order ID of the order to cancel.
 * @param {Order} order - Order - this is the order that was cancelled
 */
export const cancelOrderSuccess = (orderId: string, order: Order) =>
  action(types.OrdersTypes.CANCEL_ORDER_SUCCESS, {
    orderId,
    order,
  })

/**
 * It returns an action object with the type CANCEL_ORDER_FAILURE and the error
 * object
 * @param {any} error - any
 */
export const cancelOrderFailure = (error: any) =>
  action(types.OrdersTypes.CANCEL_ORDER_FAILURE, {
    error,
  })

export const cancelPendingOrderRequest = (orderId: string) =>
  action(types.OrdersTypes.CANCEL_PENDING_ORDER_REQUEST, { orderId })

/**
 * It returns an action object with the type CANCEL_ORDER_SUCCESS and the orderId
 * and order as payload
 * @param {string} orderId - The order ID of the order to cancel.
 * @param {Order} order - Order - this is the order that was cancelled
 */
export const cancelPendingOrderSuccess = (orderId: string, order: Order) =>
  action(types.OrdersTypes.CANCEL_PENDING_ORDER_SUCCESS, {
    orderId,
    order,
  })

/**
 * It returns an action object with the type CANCEL_ORDER_FAILURE and the error
 * object
 * @param {any} error - any
 */
export const cancelPendingOrderFailure = (error: any) =>
  action(types.OrdersTypes.CANCEL_PENDING_ORDER_FAILURE, {
    error,
  })

/**
 * It returns an action object with the type `REQUEST_REFUND_REQUEST` and the
 * orderId as the payload
 * @param {string} orderId - The order ID of the order you want to refund.
 */
export const requestRefundRequest = (orderId: string) =>
  action(types.OrdersTypes.REQUEST_REFUND_REQUEST, { orderId })

/**
 * It returns an action object with a type of `REQUEST_REFUND_SUCCESS`
 */
export const requestRefundSuccess = () => action(types.OrdersTypes.REQUEST_REFUND_SUCCESS)

/**
 * It returns an action object with the type of REQUEST_REFUND_FAILURE and the
 * error object
 * @param {Error} error - Error - The error that was thrown
 */
export const requestRefundFailure = (error: Error) =>
  action(types.OrdersTypes.REQUEST_REFUND_FAILURE, { error })

export const getAllOrdersInit = () => action(types.OrdersTypes.GET_ALL_INIT)

/**
 * It returns an action object with a type of GET_ALL_REQUEST and a payload of an
 * object with itemsPerPage and page properties
 * @param status
 * @param classStatus
 * @param [itemsPerPage=10] - The number of items to be displayed per page.
 * @param [page=1] - The page number of the orders to be fetched.
 */
export const getAllOrdersRequest = (
  status = null,
  classStatus = null,
  itemsPerPage = 10,
  page = 1,
) => action(types.OrdersTypes.GET_ALL_REQUEST, { status, classStatus, itemsPerPage, page })

/**
 * It returns an action object with the type of GET_ALL_SUCCESS and the orders,
 * page, and totalItems as the payload
 * @param {Order[]} orders - Order[] - the array of orders
 * @param {Page} page - Page - this is the page object that contains the current
 * page number, page size, and total number of items.
 * @param {number} totalItems - The total number of items in the database.
 */
export const getAllOrdersSuccess = (orders: Order[], page: Page, totalItems: number) =>
  action(types.OrdersTypes.GET_ALL_SUCCESS, {
    orders,
    page,
    totalItems,
  })

export const getAllOrdersFailure = (error: any) =>
  action(types.OrdersTypes.GET_ALL_FAILURE, {
    error,
  })

export const getClassesTeacherOrdersRequest = (itemsPerPage = 10, page = 1) =>
  action(types.OrdersTypes.GET_CLASSES_TEACHER_REQUEST, { itemsPerPage, page })

export const getClassesTeacherOrdersSuccess = (orders: Order[], page: Page, totalItems: number) =>
  action(types.OrdersTypes.GET_CLASSES_TEACHER_SUCCESS, {
    orders,
    page,
    totalItems,
  })

export const getClassesTeacherOrdersFailure = (error: any) =>
  action(types.OrdersTypes.GET_CLASSES_TEACHER_FAILURE, {
    error,
  })
