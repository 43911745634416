import { Modality as BaseModality } from '../../../types/Modality'

/**
 * Action Types
 */
export enum ModalityTypes {
  LIST_MODALITY_REQUEST = '@modality/request/listModalities',
  LIST_MODALITY_SUCCESS = '@modality/success/listModalities',
  LIST_MODALITY_FAILURE = '@modality/failure/listModalities',
}

/**
 * Data Types
 */
export interface Modality extends BaseModality {}

/**
 * State type
 */
export interface ModalityState {
  readonly data: Modality[]
  readonly error: string
  readonly isError: boolean
  readonly isLoading: boolean
  readonly totalItems: number
}
