import { action } from 'typesafe-actions'
import { Error } from '../../../types/Error'
import { types } from '../index'
import { FAQ } from './types'

/**
 * It returns an action object with a type of `FAQTypes.FAQ_REQUEST`
 */
export const getAllFAQRequest = () => action(types.FAQTypes.GET_ALL_FAQ_REQUEST)

/**
 * It takes an array of FAQs and returns an action with the type FAQ_SUCCESS and
 * the data payload
 * @param {FAQ[]} data - FAQ[] - this is the data that will be passed to the
 * reducer.
 */
export const getAllFAQSuccess = (data: FAQ[]) =>
  action(types.FAQTypes.GET_ALL_FAQ_SUCCESS, { data })

/**
 * It returns an action object with the type of `FAQTypes.FAQ_FAILURE` and the
 * error object
 * @param {Error} error - Error - The error object that was thrown
 */
export const getAllFAQFailure = (error: Error) =>
  action(types.FAQTypes.GET_ALL_FAQ_FAILURE, { error })
