import { FAQ as FAQBase } from './../../../types/FAQ'
/**
 * Action Types
 */
export enum FAQTypes {
  GET_ALL_FAQ_REQUEST = '@faq/get_all/request',
  GET_ALL_FAQ_SUCCESS = '@faq/get_all/success',
  GET_ALL_FAQ_FAILURE = '@faq/get_all/failure',
}

/**
 * Data Types
 */
export interface FAQ extends FAQBase {}

/**
 * State type
 */
export interface FAQState {
  readonly dataAll: FAQ[]
  readonly error: Error
  readonly isError: boolean
  readonly isLoading: boolean
  readonly totalItems: number
}
