/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { Snackbar, Alert, AlertTitle, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import theme from './themes'
import Routes from './routes'
import { HistoryRouter } from './components/HistoryRouter'
import history from './routes/history'
import { AuthProvider } from './contexts/auth'

import { ApplicationState } from './store'
import { AuthState } from './store/ducks/Auth/types'
import { NotificationState } from './store/ducks/Notification/types'

import * as AuthActions from './store/ducks/Auth/actions'
import * as NotificationActions from './store/ducks/Notification/actions'

interface StateProps {
  auth: AuthState
  notification: NotificationState
}

interface DispatchProps {
  loginRequest(username: string, password: string, redirect: boolean): void
  logoutRequest(): void
  notificationCloseRequest(): void
}

type AppProps = StateProps & DispatchProps

const App = (props: AppProps) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider state={props}>
        <HistoryRouter history={history}>
          <Routes />
          <Snackbar
            open={props?.notification?.isOpen}
            autoHideDuration={props?.notification?.autoHideDuration}
            anchorOrigin={props?.notification?.anchorOrigin}
            onClose={(_?: React.SyntheticEvent | Event, reason?: string) => {
              if (reason === 'clickaway') return
              props?.notificationCloseRequest()
            }}
          >
            <Alert
              variant={props?.notification?.variant}
              severity={props?.notification?.severity}
              onClose={() => props?.notificationCloseRequest()}
            >
              <AlertTitle>{props?.notification?.title}</AlertTitle>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: props?.notification?.message,
                }}
              ></Typography>
            </Alert>
          </Snackbar>
        </HistoryRouter>
      </AuthProvider>
    </ThemeProvider>
  )
}

const mapProps = (app: ApplicationState) => {
  return {
    auth: app.auth,
    notification: app.notification,
  }
}

const Actions = {
  ...AuthActions,
  ...NotificationActions,
}

const mapDispatch = (dispatch: Dispatch) => bindActionCreators(Actions, dispatch)

export default connect(mapProps, mapDispatch)(App)
