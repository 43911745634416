import { User } from '../Auth/types'

/**
 * Action Types
 */
export enum TeacherTypes {
  REQUEST_GET_TEACHER = '@teacher/request_get_teacher',
  SUCCESS_GET_TEACHER = '@teacher/success_get_teacher',
  FAILURE_GET_TEACHER = '@teacher/failure_get_teacher',

  REQUEST_GET_TEACHER_ALL = '@teacher/request_get_teacher_all',
  SUCCESS_GET_TEACHER_ALL = '@teacher/success_get_teacher_all',
  FAILURE_GET_TEACHER_ALL = '@teacher/failure_get_teacher_all',
}

/**
 * Data Types
 */
export interface Teacher extends User {}

/**
 * State type
 */
export interface TeacherState {
  readonly data: Teacher[]
  readonly teacher: Teacher
  readonly teacherId: number
  readonly loading: boolean
  readonly error: boolean
}
