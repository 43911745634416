import { DashboardNotification as DashboardNotificationBase } from '../../../types/DashboardNotification'
/**
 * Action Types
 */
export enum DashboardNotificationTypes {
  DASHBOARD_NOTIFICATION_REQUEST = '@dashboardNotification/request',
  DASHBOARD_NOTIFICATION_SUCCESS = '@dashboardNotification/success',
  DASHBOARD_NOTIFICATION_FAILURE = '@dashboardNotification/failure',
}

/**
 * Data Types
 */
export interface DashboardNotification extends DashboardNotificationBase {}

/**
 * State type
 */
export interface DashboardNotificationState {
  readonly dataAll: DashboardNotification[]
  readonly error: Error
  readonly isError: boolean
  readonly isLoading: boolean
}
