import { Reducer } from 'redux'
import { SettingsState, SettingsTypes } from './types'

const INITIAL_STATE: SettingsState = {
  settings: [],
}

const reducer: Reducer<SettingsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SettingsTypes.GET_ALL_SETTINGS_REQUEST:
      return {
        ...state,
        settings: [],
      }
    case SettingsTypes.GET_ALL_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload.data,
      }
    case SettingsTypes.GET_ALL_SETTINGS_FAILURE:
      return {
        ...state,
        settings: [],
      }
    default:
      return state
  }
}

export default reducer
