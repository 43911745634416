import { Reducer } from 'redux'
import { BlogTypes, BlogState } from './types'

const INITIAL_STATE: BlogState = {
  post: null,
  postAll: [],

  category: null,
  categoryAll: [],

  isLoading: false,
  isError: false,
}

const reducer: Reducer<BlogState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BlogTypes.LIST_POST_REQUEST:
      return {
        ...state,
        postAll: [],
        isLoading: true,
        isError: false,
      }
    case BlogTypes.LIST_POST_SUCCESS:
      return {
        ...state,
        postAll: action.payload.data['hydra:member'],
        isLoading: false,
        isError: false,
      }
    case BlogTypes.LIST_POST_FAILURE:
      return {
        ...state,
        postAll: [],
        isLoading: false,
        isError: true,
      }

    case BlogTypes.GET_POST_REQUEST:
      return {
        ...state,
        post: null,
        isLoading: true,
        isError: false,
      }
    case BlogTypes.GET_POST_SUCCESS:
      return {
        ...state,
        post: action.payload.post,
        isLoading: false,
        isError: false,
      }
    case BlogTypes.GET_POST_FAILURE:
      return {
        ...state,
        post: null,
        isLoading: false,
        isError: true,
      }

    case BlogTypes.LIST_CATEGORY_REQUEST:
      return {
        ...state,
        categoryAll: [],
        isLoading: true,
        isError: false,
      }
    case BlogTypes.LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryAll: action.payload.data['hydra:member'],
        isLoading: false,
        isError: false,
      }
    case BlogTypes.LIST_CATEGORY_FAILURE:
      return {
        ...state,
        categoryAll: [],
        isLoading: false,
        isError: true,
      }

    case BlogTypes.GET_CATEGORY_REQUEST:
      return {
        ...state,
        category: null,
        isLoading: true,
        isError: false,
      }
    case BlogTypes.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload.data,
        isLoading: false,
        isError: false,
      }
    case BlogTypes.GET_CATEGORY_FAILURE:
      return {
        ...state,
        category: null,
        isLoading: false,
        isError: true,
      }
    default:
      return state
  }
}

export default reducer
