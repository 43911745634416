import { createTheme } from '@mui/material/styles'
import AvatarFallback from '../assets/layout/Avatar-Fallback.svg'

// A custom theme for this app
const theme = createTheme({
  /*
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1300,
    },
  },
  */
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#33cc99',
      dark: '#0f9b6c',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#173042',
      dark: '#0000ba',
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          '.MuiAvatar-fallback': {
            background: `url(${AvatarFallback}) no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
            path: {
              display: 'none',
            },
          },
        },
      },
    },
  },
})

export const themeApp = {
  maxWidth: '1260px',
  backgroundColor: '#e0efea',
  hoverColor: '#6b66a3',
}

export default theme
