import { put } from 'redux-saga/effects'
import { notificationOpenRequest } from './actions'

export function* notificationOpen(data: any): any {
  try {
    yield put(
      notificationOpenRequest(data.title, data.message, data.severity, data.autoHideDuration),
    )
  } catch (error) {
    console.error('Ocorreu um error na notificação', error)
  }
}
