import { Reducer } from 'redux'
import { Installments, SubscriptionsState, SubscriptionsTypes } from './types'

const INITIAL_STATE: SubscriptionsState = {
  subscriptions: [],
  plans: [],
  plan: null,
  activeSubscription: null,

  payment: null,
  planId: null,
  calculateInstallments: null,
  transaction: null,
  planCoupon: null,
  orderValue: 0,

  error: null,
  isError: false,

  isLoading: false,
  isTransactionError: false,
  totalItems: 0,
}

const reducer: Reducer<SubscriptionsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SubscriptionsTypes.LIST_PLANS_REQUEST:
      return {
        ...state,
        plans: [],
        error: null,
        isLoading: true,
        isError: false,
        totalItems: 0,
      }
    case SubscriptionsTypes.LIST_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.payload.data['hydra:member'],
        message: '',
        isLoading: false,
        isError: false,
        totalItems: action.payload.data['hydra:totalItems'],
      }
    case SubscriptionsTypes.LIST_PLANS_FAILURE:
      return {
        ...state,
        plans: [],
        error: action.payload.error,
        isLoading: false,
        isError: true,
        totalItems: 0,
      }

    //NEW_SUBSCRIPTION
    case SubscriptionsTypes.NEW_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        planId: action.payload.planId,
        calculateInstallments: null,
        payment: null,
        transaction: null,
        error: null,
        isError: false,
        isLoading: true,
      }
    }
    case SubscriptionsTypes.NEW_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isError: true,
      }
    }

    // PURCHASE
    case SubscriptionsTypes.PURCHASE_REQUEST:
      return {
        ...state,
        planId: action.payload.planId,
        plan: null,
        activeSubscription: null,
        error: null,
        isLoading: true,
        isError: false,
      }
    case SubscriptionsTypes.PURCHASE_SUCCESS:
      return {
        ...state,
        plan: action.payload.plan,
        activeSubscription: null,
        error: null,
        isLoading: false,
        isError: false,
      }
    case SubscriptionsTypes.PURCHASE_FAILURE:
      return {
        ...state,
        plan: null,
        activeSubscription: null,
        error: action.payload.error,
        isLoading: false,
        isError: true,
      }

    // PAYMENT
    case SubscriptionsTypes.PAYMENT_INIT:
      return {
        ...state,
        orderValue: state?.plan?.value,
        activeSubscription: null,
        transaction: null,
        error: null,
        isLoading: false,
        isError: false,
        isTransactionError: false,
      }
    case SubscriptionsTypes.PAYMENT_WAITING:
      return {
        ...state,
        activeSubscription: null,
        transaction: action.payload.transaction,
        error: null,
        isLoading: false,
        isError: false,
      }
    case SubscriptionsTypes.PAYMENT_REQUEST:
      return {
        ...state,
        activeSubscription: null,
        error: null,
        isLoading: true,
        isError: false,
      }
    case SubscriptionsTypes.PAYMENT_SUCCESS:
      return {
        ...state,
        activeSubscription: action.payload.activeSubscription,
        error: null,
        isLoading: false,
        isError: false,
      }
    case SubscriptionsTypes.PAYMENT_FAILURE:
      return {
        ...state,
        activeSubscription: null,
        error: action.payload.error,
        isLoading: false,
        isError: true,
      }

    case SubscriptionsTypes.PAYMENT_TRY_AGAIN: {
      return {
        ...state,
        transaction: null,
        isTransactionError: false,
      }
    }

    // PAYMENT_TRANSACTION
    case SubscriptionsTypes.PAYMENT_TRANSACTION_SUCCESS: {
      return {
        ...state,
        transaction: action.payload.transaction,
        isTransactionError: true,
        error: null,
        isError: false,
        isLoading: false,
      }
    }
    case SubscriptionsTypes.PAYMENT_TRANSACTION_FAILURE: {
      return {
        ...state,
        transaction: null,
        isTransactionError: false,
        error: action.payload.error,
        isError: true,
        isLoading: false,
      }
    }

    // CAlCULATE_INSTALLMENTS
    case SubscriptionsTypes.CAlCULATE_INSTALLMENTS_REQUEST: {
      return {
        ...state,
        calculateInstallments: null,
      }
    }
    case SubscriptionsTypes.CAlCULATE_INSTALLMENTS_SUCCESS: {
      const array = Object.values<Installments>(action.payload.installments)

      return {
        ...state,
        calculateInstallments: {
          amount: action.payload.amount,
          installments: array,
        },
      }
    }
    case SubscriptionsTypes.CAlCULATE_INSTALLMENTS_FAILURE: {
      return {
        ...state,
        calculateInstallments: null,
      }
    }

    case SubscriptionsTypes.DISCOUNTS_COUPON_INIT:
      return {
        ...state,
        planCoupon: null,
        orderValue: state?.plan?.value,
        isError: false,
        error: null,
      }

    case SubscriptionsTypes.DISCOUNTS_COUPON_REQUEST:
      return {
        ...state,
        planCoupon: action.payload.discountCoupon,
        orderValue: state?.plan?.value,
        isError: false,
        error: null,
      }
    case SubscriptionsTypes.DISCOUNTS_COUPON_SUCCESS:
      return {
        ...state,
        planCoupon: action.payload.discountCoupon,
        orderValue: action.payload.orderValue,
        isError: false,
        error: null,
      }
    case SubscriptionsTypes.DISCOUNTS_COUPON_FAILURE:
      return {
        ...state,
        planCoupon: null,
        orderValue: state?.plan?.value,
        isError: true,
        error: action.payload.error,
      }

    default:
      return state
  }
}

export default reducer
