import { Error } from '../../../types/Error'
import { Invites as BaseInvites } from '../../../types/Invites'

/**
 * Action Types
 */
export enum InvitesTypes {
  LIST_INVITES_REQUEST = '@invites/request/listInvites',
  LIST_INVITES_SUCCESS = '@invites/success/listInvites',
  LIST_INVITES_FAILURE = '@invites/failure/listInvites',

  SEND_INVITES_REQUEST = '@invites/request/sendInvites',
  SEND_INVITES_SUCCESS = '@invites/success/sendInvites',
  SEND_INVITES_FAILURE = '@invites/failure/sendInvites',
}

/**
 * Data Types
 */
export interface Invites extends BaseInvites {}

/**
 * State type
 */
export interface InvitesState {
  readonly data: Invites
  readonly dataAll: Invites[]
  readonly error: Error
  readonly isError: boolean
  readonly isLoading: boolean
  readonly totalItems: number
}
