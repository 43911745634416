import { ValidationsError } from '../../../types/Error/Validations'
import { Contact as ContactBase } from './../../../types/Contact'
/**
 * Action Types
 */
export enum ContactTypes {
  SEND_MESSAGE_INIT = '@contact/send_message/init',
  SEND_MESSAGE_REQUEST = '@contact/send_message/request',
  SEND_MESSAGE_SUCCESS = '@contact/send_message/success',
  SEND_MESSAGE_FAILURE = '@contact/send_message/failure',
}

/**
 * Data Types
 */
export interface Contact extends ContactBase {}

/**
 * State type
 */
export interface ContactState {
  readonly data: Contact
  readonly error: Error
  readonly isError: boolean
  readonly isLoading: boolean
  readonly validations: ValidationsError[]
}
