import { action } from 'typesafe-actions'
import { Error } from '../../../types/Error'
import { types } from '../index'
import { DashboardNotification } from './types'

export const getDashboardNotificationRequest = () =>
  action(types.DashboardNotificationTypes.DASHBOARD_NOTIFICATION_REQUEST)

export const getDashboardNotificationSuccess = (data: DashboardNotification[]) =>
  action(types.DashboardNotificationTypes.DASHBOARD_NOTIFICATION_SUCCESS, { data })

export const getDashboardNotificationFailure = (error: Error) =>
  action(types.DashboardNotificationTypes.DASHBOARD_NOTIFICATION_FAILURE, { error })
