import { action } from 'typesafe-actions'
import { CreditCard } from '../../../types/CreditCard'
import { DiscountCoupon } from '../../../types/DiscountCoupon'
import { Error } from '../../../types/Error'
import { Plan } from '../../../types/Plan'
import { Transaction } from '../../../types/Transaction'
import { types } from '../index'
import { Subscriptions } from './types'

/**
 * It returns an action object with a type of
 * `SubscriptionsTypes.LIST_PLANS_REQUEST`
 */
export const getListPlansRequest = () => action(types.SubscriptionsTypes.LIST_PLANS_REQUEST)

/**
 * It returns an action object with a type of
 * `SubscriptionsTypes.LIST_PLANS_SUCCESS` and a payload of `data`
 * @param {Plan[]} data - Plan[] - the data that will be passed to the reducer
 */
export const getListPlansSuccess = (data: Plan[]) =>
  action(types.SubscriptionsTypes.LIST_PLANS_SUCCESS, { data })

/**
 * It returns an action object with the type
 * `SubscriptionsTypes.LIST_PLANS_FAILURE` and the error object
 * @param {any} error - any
 */
export const getListPlansFailure = (error: Error) =>
  action(types.SubscriptionsTypes.LIST_PLANS_FAILURE, { error })

/**
 * It returns an action object with the type `SubscriptionsTypes.GET_PLAN_REQUEST`
 * and the payload `{ planId }`
 * @param {string} planId - The ID of the plan to get.
 */
export const getPlanRequest = (planId: string) =>
  action(types.SubscriptionsTypes.GET_PLAN_REQUEST, { planId })

/**
 * It returns an action object with the type `SubscriptionsTypes.GET_PLAN_SUCCESS`
 * and the payload `{ plan }`
 * @param {Plan} plan - Plan - this is the type of the parameter that will be
 * passed to the action creator.
 */
export const getPlanSuccess = (plan: Plan) =>
  action(types.SubscriptionsTypes.GET_PLAN_SUCCESS, { plan })

/**
 * It returns an action object with the type `SubscriptionsTypes.GET_PLAN_FAILURE`
 * and the error object
 * @param {Error} error - Error - The error that was thrown
 */
export const getPlanFailure = (error: Error) =>
  action(types.SubscriptionsTypes.GET_PLAN_FAILURE, {
    error,
  })

/**
 * It returns an action object with a type of NEW_SUBSCRIPTION_REQUEST
 * @param {string} planId - string
 */
export const newSubscriptionRequest = (planId: string) =>
  action(types.SubscriptionsTypes.NEW_SUBSCRIPTION_REQUEST, {
    planId,
  })

/**
 * It returns an action object with a type of NEW_SUBSCRIPTION_FAILURE and an error
 * property
 * @param {any} error - any
 */
export const newSubscriptionFailure = (error: Error) =>
  action(types.SubscriptionsTypes.NEW_SUBSCRIPTION_FAILURE, { error })

/**
 * It returns an action object with the type CANCEL_SUBSCRIPTION_REQUEST
 * @param {string} id - The id of the subscription to cancel
 * @param {number} status - number - The status code of the response.
 */
export const cancelSubscriptionRequest = (id: string, status: number) =>
  action(types.SubscriptionsTypes.CANCEL_SUBSCRIPTION_REQUEST, { id, status })

/**
 * It returns an action object with the type CANCEL_SUBSCRIPTION_FAILURE and the
 * error object.
 * @param {any} error - any
 */
export const cancelSubscriptionFailure = (error: Error) =>
  action(types.SubscriptionsTypes.CANCEL_SUBSCRIPTION_FAILURE, { error })

/**
 ############### Purchase ###############
*/
export const purchaseRequest = (planId: string) =>
  action(types.SubscriptionsTypes.PURCHASE_REQUEST, {
    planId,
  })

export const purchaseSuccess = (plan: Subscriptions) =>
  action(types.SubscriptionsTypes.PURCHASE_SUCCESS, { plan })

export const purchaseFailure = (error: Error) =>
  action(types.SubscriptionsTypes.PURCHASE_FAILURE, { error })

/**
 ############### Payment ###############
*/
export const paymentInit = () => action(types.SubscriptionsTypes.PAYMENT_INIT)

export const paymentRequest = (
  planId: string,
  paymentMethod: string,
  creditCard?: CreditCard,
  planCoupon?: string,
  autoRenew?: boolean,
) =>
  action(types.SubscriptionsTypes.PAYMENT_REQUEST, {
    planId,
    paymentMethod,
    creditCard,
    planCoupon,
    autoRenew,
  })

export const paymentWaiting = (transaction: Transaction) =>
  action(types.SubscriptionsTypes.PAYMENT_WAITING, {
    transaction,
  })

export const paymentSuccess = (activeSubscription: Subscriptions) =>
  action(types.SubscriptionsTypes.PAYMENT_SUCCESS, { activeSubscription })

export const paymentFailure = (error: Error) =>
  action(types.SubscriptionsTypes.PAYMENT_FAILURE, { error })

export const paymentTryAgain = () => action(types.SubscriptionsTypes.PAYMENT_TRY_AGAIN)

/**
 ############### Transaction ###############
*/
export const transactionSuccess = (transaction: Transaction) =>
  action(types.SubscriptionsTypes.PAYMENT_TRANSACTION_SUCCESS, { transaction })

export const transactionFailure = (transaction: Transaction) =>
  action(types.SubscriptionsTypes.PAYMENT_TRANSACTION_FAILURE, { transaction })

/**
 ############### Calculate Installments ###############
*/
/**
 * It returns an action object with the type `CALCULATE_INSTALLMENTS_REQUEST` and
 * the payload `{ amount }`
 * @param {number} amount - number
 */
export const calculateInstallmentsRequest = (amount: number) =>
  action(types.SubscriptionsTypes.CAlCULATE_INSTALLMENTS_REQUEST, { amount })

/**
 * It returns an action object with the type `CALCULATE_INSTALLMENTS_SUCCESS` and
 * the payload `{ amount, installments }`
 * @param {number} amount - number - The amount of the purchase
 * @param {number} installments - number - The number of installments
 */
export const calculateInstallmentsSuccess = (amount: number, installments: number) =>
  action(types.SubscriptionsTypes.CAlCULATE_INSTALLMENTS_SUCCESS, { amount, installments })

/**
 * It returns an action object with the type `CALCULATE_INSTALLMENTS_FAILURE` and
 * the error object
 * @param {any} error - any
 */
export const calculateInstallmentsFailure = (error: Error) =>
  action(types.SubscriptionsTypes.CAlCULATE_INSTALLMENTS_FAILURE, { ...error })

/**
 ############### Discounts Coupon ###############
*/
export const discountCouponInit = (coupon: string, value: number, id?: string) =>
  action(types.SubscriptionsTypes.DISCOUNTS_COUPON_INIT, { coupon, value, id })

/**
 * It returns an action object with the type
 * `ScheduleTypes.DISCOUNTS_COUPON_REQUEST` and the payload `{ coupon }`
 * @param {string} coupon - string - the coupon code
 * @param value
 * @param id
 */
export const discountCouponRequest = (coupon: string, value: number, id?: string) =>
  action(types.SubscriptionsTypes.DISCOUNTS_COUPON_REQUEST, { coupon, value, id })

/**
 * It returns an action object with a type of
 * `ScheduleTypes.DISCOUNTS_COUPON_SUCCESS`
 */
export const discountCouponSuccess = (discountCoupon: DiscountCoupon, orderValue: number) =>
  action(types.SubscriptionsTypes.DISCOUNTS_COUPON_SUCCESS, {
    discountCoupon,
    orderValue,
  })

/**
 * It returns an action object with the type
 * `ScheduleTypes.DISCOUNTS_COUPON_FAILURE` and the error object
 * @param {Error} error - Error - The error object that was thrown
 */
export const discountCouponFailure = (error: Error) =>
  action(types.SubscriptionsTypes.DISCOUNTS_COUPON_FAILURE, { error })
