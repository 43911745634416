import { SnackbarOrigin } from '@mui/material'

/**
 * Action Types
 */
export enum NotificationTypes {
  NOTIFICATION_OPEN = '@notification/open',
  NOTIFICATION_CLOSE = '@notification/close',
}

/**
 * Data Types
 */
export interface Notification {
  title: string
  message: string
  severity?: 'success' | 'info' | 'warning' | 'error'
  autoHideDuration?: number
  anchorOrigin?: SnackbarOrigin
  variant?: 'standard' | 'filled' | 'outlined'
  isOpen: boolean
}

/**
 * State type
 */
export interface NotificationState extends Notification {}
