import { Specialization as BaseSpecialization } from '../../../types/Specialization'

/**
 * Action Types
 */
export enum SpecializationTypes {
  LIST_SPECIALIZATIONS_REQUEST = '@specializations/request/listSpecializations',
  LIST_SPECIALIZATIONS_SUCCESS = '@specializations/success/listSpecializations',
  LIST_SPECIALIZATIONS_FAILURE = '@specializations/failure/listSpecializations',
}

/**
 * Data Types
 */
export interface Specialization extends BaseSpecialization {}

/**
 * State type
 */
export interface SpecializationState {
  readonly data: Specialization[]
  readonly error: string
  readonly isError: boolean
  readonly isLoading: boolean
  readonly totalItems: number
}
