import axios, { AxiosError } from 'axios'
import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import { notificationOpenRequest } from '../Notification/actions'
import { Error } from './../../../types/Error'
import { send_message_success, send_message_failure } from './actions'

export function* send_message(data: any): any {
  try {
    yield call(api.post, '/contact_messages', data?.payload.contact)
    yield put(send_message_success())
    yield put(
      notificationOpenRequest('Informação', 'Mensagem enviada com sucesso!', 'success', 4000),
    )
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      yield put(
        send_message_failure({
          'hydra:title': error?.response?.data?.['hydra:title'],
          'hydra:description': error?.response?.data?.['hydra:description'],
          status: error?.response?.status,
          violations: error?.response?.data?.violations,
        }),
      )
    }
  }
}
