import { Error } from '../../../types/Error'
import { Subscriptions as SubscriptionsBase } from '../../../types/Subscriptions'
import { Transaction } from '../../../types/Transaction'
import { Plan } from '../../../types/Plan'
import { DiscountCoupon } from '../../../types/DiscountCoupon'

/**
 * Action Types
 */
export enum SubscriptionsTypes {
  LIST_PLANS_REQUEST = '@subscriptions/getListPlans/request',
  LIST_PLANS_SUCCESS = '@subscriptions/getListPlans/success',
  LIST_PLANS_FAILURE = '@subscriptions/getListPlans/failure',

  GET_PLAN_REQUEST = '@subscriptions/request/getPlan/request',
  GET_PLAN_SUCCESS = '@subscriptions/request/getPlan/success',
  GET_PLAN_FAILURE = '@subscriptions/request/getPlan/failure',

  NEW_SUBSCRIPTION_REQUEST = '@subscriptions/newSubscription/request',
  NEW_SUBSCRIPTION_FAILURE = '@subscriptions/newSubscription/failure',

  CANCEL_SUBSCRIPTION_REQUEST = '@subscriptions/newSubscription/request',
  CANCEL_SUBSCRIPTION_FAILURE = '@subscriptions/cancelSubscription/failure',

  PURCHASE_REQUEST = '@subscriptions/purchase/request',
  PURCHASE_SUCCESS = '@subscriptions/purchase/success',
  PURCHASE_FAILURE = '@subscriptions/purchase/failure',

  PAYMENT_INIT = '@subscriptions/payment/init',
  PAYMENT_WAITING = '@subscriptions/payment/waiting_payment',
  PAYMENT_REQUEST = '@subscriptions/payment/request',
  PAYMENT_SUCCESS = '@subscriptions/payment/success',
  PAYMENT_FAILURE = '@subscriptions/payment/failure',

  PAYMENT_TRY_AGAIN = '@subscriptions/payment_try_again',

  PAYMENT_TRANSACTION_SUCCESS = '@subscriptions/payment/transaction/success',
  PAYMENT_TRANSACTION_FAILURE = '@subscriptions/payment/transaction/failure',

  CAlCULATE_INSTALLMENTS_REQUEST = '@subscriptions/payment/calculate_installments/request',
  CAlCULATE_INSTALLMENTS_SUCCESS = '@subscriptions/payment/calculate_installments/success',
  CAlCULATE_INSTALLMENTS_FAILURE = '@subscriptions/payment/calculate_installments/failure',

  DISCOUNTS_COUPON_INIT = '@subscriptions/discounts_coupon/init',
  DISCOUNTS_COUPON_REQUEST = '@subscriptions/discounts_coupon/request',
  DISCOUNTS_COUPON_SUCCESS = '@subscriptions/discounts_coupon/success',
  DISCOUNTS_COUPON_FAILURE = '@subscriptions/discounts_coupon/failure',
}

/**
 * Data Types
 */
export interface Subscriptions extends SubscriptionsBase {}

export interface Payment {
  schedule: string
  paymentMethod: string
  creditCard?: {
    number: string
    name: string
    expiryDate: string
    cvv: string
    installments: number
  }
}

export interface Installments {
  [key: string]: {
    installment: number
    amount: number
    installment_amount: number
  }
}

interface CalculateInstallments {
  amount: number
  installments: Installments[]
}

/**
 * State type
 */
export interface SubscriptionsState {
  readonly subscriptions: Subscriptions[]
  readonly plans: Plan[]

  readonly plan: Plan
  readonly activeSubscription: Subscriptions

  readonly planId: string
  readonly payment: Payment
  readonly calculateInstallments: CalculateInstallments
  readonly planCoupon: DiscountCoupon
  readonly orderValue: number

  readonly transaction: Transaction
  readonly isTransactionError: boolean

  readonly error: Error
  readonly isError: boolean

  readonly isLoading: boolean
  readonly totalItems: number
}
