import { Contents } from '../../../types/Contents'

/**
 * Action Types
 */
export enum ContentsTypes {
  GET_CONTENT_REQUEST = '@contents/request/find',
  GET_CONTENT_SUCCESS = '@contents/success/find',
  GET_CONTENT_FAILURE = '@contents/failure/find',
}

/**
 * Data Types
 */
export interface Content extends Contents {}

/**
 * State type
 */
export interface ContentsState {
  readonly data: Content[]
  loading: boolean
  error: boolean
}
