import { Post as PostBase } from '../../../types/Blog/Post'
import { Category as CategoryBase } from '../../../types/Blog/Category'

/**
 * Action Types
 */
export enum BlogTypes {
  LIST_POST_REQUEST = '@post/list_post/request',
  LIST_POST_SUCCESS = '@post/list_post/success',
  LIST_POST_FAILURE = '@post/list_post/failure',

  GET_POST_REQUEST = '@post/get_post/request',
  GET_POST_SUCCESS = '@post/get_post/success',
  GET_POST_FAILURE = '@post/get_post/failure',

  LIST_CATEGORY_REQUEST = '@category/list_category/request',
  LIST_CATEGORY_SUCCESS = '@category/list_category/success',
  LIST_CATEGORY_FAILURE = '@category/list_category/failure',

  GET_CATEGORY_REQUEST = '@category/get_category/request',
  GET_CATEGORY_SUCCESS = '@category/get_category/success',
  GET_CATEGORY_FAILURE = '@category/get_category/failure',
}

/**
 * Data Types
 */
export interface Post extends PostBase {}
export interface Category extends CategoryBase {}

/**
 * State type
 */
export interface BlogState {
  readonly post: Post
  readonly postAll: Post[]

  readonly category: Category
  readonly categoryAll: Category[]

  readonly isLoading: boolean
  readonly isError: boolean
}
