import { action } from 'typesafe-actions'
import { Teacher } from './types'
import { types } from '../index'

export const getTeacherAllRequest = () => action(types.TeacherTypes.REQUEST_GET_TEACHER_ALL)
export const getTeacherAllSuccess = (data: Teacher[]) =>
  action(types.TeacherTypes.SUCCESS_GET_TEACHER_ALL, { data })
export const getTeacherAllFailure = () => action(types.TeacherTypes.FAILURE_GET_TEACHER_ALL)

export const getTeacherRequest = (teacherId: number) =>
  action(types.TeacherTypes.REQUEST_GET_TEACHER, { teacherId })
export const getTeacherSuccess = (teacher: Teacher) =>
  action(types.TeacherTypes.SUCCESS_GET_TEACHER, { teacher })
export const getTeacherFailure = () => action(types.TeacherTypes.FAILURE_GET_TEACHER)
