import { action } from 'typesafe-actions'
import { Error } from '../../../types/Error'
import { types } from '../index'
import { PrivacyPolicies } from './types'

export const getPrivacyPoliciesRequest = () =>
  action(types.PrivacyPoliciesTypes.GET_PRIVACY_POLICIES_REQUEST)
export const getPrivacyPoliciesSuccess = (data: PrivacyPolicies) =>
  action(types.PrivacyPoliciesTypes.GET_PRIVACY_POLICIES_SUCCESS, { data })
export const getPrivacyPoliciesFailure = (error: Error) =>
  action(types.PrivacyPoliciesTypes.GET_PRIVACY_POLICIES_FAILURE, { error })
