import { action } from 'typesafe-actions'
import { Error } from '../../../types/Error'
import { types } from '../index'
import { TermsOfUse } from './types'

export const getTermsOfUseRequest = () => action(types.TermsOfUseTypes.GET_TERMS_OF_USE_REQUEST)
export const getTermsOfUseSuccess = (data: TermsOfUse) =>
  action(types.TermsOfUseTypes.GET_TERMS_OF_USE_SUCCESS, { data })
export const getTermsOfUseFailure = (error: Error) =>
  action(types.TermsOfUseTypes.GET_TERMS_OF_USE_FAILURE, { error })
