import { Review as ReviewType } from '../../../types/Review'

/**
 * Action Types
 */
export enum ReviewTypes {
  REVIEW_REQUEST_GET_ALL = '@reviews/request/getAll',
  REVIEW_SUCCESS_GET_ALL = '@reviews/success/getAll',
  REVIEW_FAILURE_GET_ALL = '@reviews/failure/getAll',

  REVIEW_REQUEST_FIND = '@reviews/request/find',
  REVIEW_SUCCESS_FIND = '@reviews/success/find',
  REVIEW_FAILURE_FIND = '@reviews/failure/find',

  REVIEW_REQUEST_ADD = '@reviews/request/add',
  REVIEW_SUCCESS_ADD = '@reviews/success/add',
  REVIEW_FAILURE_ADD = '@reviews/failure/add',
}

/**
 * Data Types
 */
export interface Review extends ReviewType {}

/**
 * State type
 */
export interface ReviewState {
  readonly reviews: Review[]
  readonly review: Review
  readonly loading: boolean
  readonly error: boolean
  readonly totalItems: number
}
