import { call, delay, put, select } from 'redux-saga/effects'
import api from '../../../services/api'
import {
  getAllOrdersRequest,
  getAllOrdersSuccess,
  getAllOrdersFailure,
  acceptOrderSuccess,
  acceptOrderFailure,
  denyOrderSuccess,
  denyOrderFailure,
  cancelOrderSuccess,
  cancelOrderFailure,
  requestRefundSuccess,
  requestRefundFailure,
} from './actions'
import { AuthState } from '../Auth/types'
import { notificationOpenRequest } from '../Notification/actions'
import { myScheduleRequest } from '../Schedule/actions'

const getAuth = (state) => state.auth

export function* getAllOrders(data: any): any {
  try {
    const currentUser: AuthState = yield select(getAuth)

    let url = ''
    let params = new Array<string>()
    if (data.payload.status) params.push(`status=${data.payload.status}`)
    if (data.payload.classStatus) params.push(`classStatus=${data.payload.classStatus}`)

    if (currentUser.role === 'teacher') url = `/orders?${params.join('&')}`
    else url = `/orders?itemsPerPage=${data.payload.itemsPerPage}&page=${data.payload.page}`

    const response = yield call(api.get, url)
    yield put(
      getAllOrdersSuccess(
        response.data['hydra:member'],
        response.data['hydra:view'],
        response.data['hydra:totalItems'],
      ),
    )
  } catch (error) {
    yield put(getAllOrdersFailure(error))
  }
}

export function* getClassesTeacherOrders(data: any): any {
  try {
    const response = yield call(
      api.get,
      `${`/orders?classStatus=7&itemsPerPage=${data.payload.itemsPerPage}&page=${data.payload.page}`}`,
    )
    yield put(
      getAllOrdersSuccess(
        response.data['hydra:member'],
        response.data['hydra:view'],
        response.data['hydra:totalItems'],
      ),
    )
  } catch (error) {
    yield put(getAllOrdersFailure(error))
  }
}

export function* requestRefund(data: any): any {
  try {
    yield call(
      api.patch,
      `/order/${data.payload.orderId}/request_refund`,
      {
        status: 3,
      },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    yield put(
      notificationOpenRequest('Sucesso', 'Seu pedido de reembolso foi realizado!', 'success', 4000),
    )
    yield put(requestRefundSuccess())
    yield put(getAllOrdersRequest())
  } catch (e) {
    yield put(
      notificationOpenRequest('Error', 'Seu pedido de reembolso não realizado!', 'error', 4000),
    )
    yield put(requestRefundFailure(e))
  }
}

export function* acceptOrder(data: any): any {
  try {
    const response = yield call(
      api.patch,
      `/order/${data.payload.orderId}/reply`,
      {
        classStatus: 1,
      },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    yield put(notificationOpenRequest('Sucesso', 'O agendamento foi aceito!', 'success', 4000))
    yield put(acceptOrderSuccess(data.payload.orderId, response.data))
    if (data.payload?.mySchedule)
      yield put(myScheduleRequest(data.payload?.mySchedule?.start, data.payload?.mySchedule?.end))
    else yield put(getAllOrdersRequest())
  } catch (e) {
    yield put(notificationOpenRequest('Atenção', 'O agendamento não foi aceito!', 'error', 4000))
    yield put(acceptOrderFailure(e))
  }
}

export function* denyOrder(data: any): any {
  try {
    const response = yield call(
      api.patch,
      `/order/${data.payload.orderId}/reply`,
      {
        classStatus: 2,
      },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    yield put(notificationOpenRequest('Sucesso', 'O agendamento foi rejeitado!', 'success', 4000))
    yield put(denyOrderSuccess(data.payload.orderId, response.data))
    if (data.payload?.mySchedule)
      yield put(myScheduleRequest(data.payload?.mySchedule?.start, data.payload?.mySchedule?.end))
    else yield put(getAllOrdersRequest())
  } catch (e) {
    yield put(notificationOpenRequest('Atenção', 'O agendamento não foi rejeitado!', 'error', 4000))
    yield put(denyOrderFailure(e))
  }
}

export function* cancelOrder(data: any): any {
  try {
    const response = yield call(
      api.patch,
      `/order/${data.payload.orderId}/cancel`,
      {
        classStatus: 3,
      },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    yield put(notificationOpenRequest('Sucesso', 'O agendamento foi cancelado!', 'success', 4000))
    yield put(cancelOrderSuccess(data.payload.orderId, response.data))
    if (data.payload?.mySchedule)
      yield put(myScheduleRequest(data.payload?.mySchedule?.start, data.payload?.mySchedule?.end))
    else yield put(getAllOrdersRequest())
  } catch (e) {
    yield put(notificationOpenRequest('Atenção', 'O agendamento não foi cancelado!', 'error', 4000))
    yield put(cancelOrderFailure(e))
  }
}

export function* cancelPendingOrder(data: any): any {
  try {
    yield call(
      api.patch,
      `/order/${data.payload.orderId}/cancel_pending`,
      {
        classStatus: 3,
      },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    yield put(notificationOpenRequest('Sucesso', 'O agendamento foi cancelado!', 'success', 4000))
    yield put(getAllOrdersRequest())
  } catch (e) {
    yield put(notificationOpenRequest('Atenção', 'O agendamento não foi cancelado!', 'error', 4000))
    yield put(cancelOrderFailure(e))
  }
}
