import { lazy } from 'react'

// project imports
import MainLayout from '../layouts/MainLayout'
import Loadable from '../ui-component/Loadable'

const SignIn = Loadable(lazy(() => import('../pages/site/Auth/SignIn')))

const SignUp = Loadable(lazy(() => import('../pages/site/Auth/Signup')))
const SignUpStudent = Loadable(lazy(() => import('../pages/site/Auth/Signup/SignupForms/Student')))
const SignUpTeacher = Loadable(lazy(() => import('../pages/site/Auth/Signup/SignupForms/Teacher')))

const EmailVerification = Loadable(lazy(() => import('../pages/site/Auth/EmailVerification')))
const ActivateAccount = Loadable(lazy(() => import('../pages/site/Auth/ActivateAccount')))
const ForgotPassword = Loadable(lazy(() => import('../pages/site/Auth/ForgotPassword')))
const Logout = Loadable(lazy(() => import('../pages/site/Auth/Logout')))

const AuthRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/login',
      children: [
        {
          index: true,
          element: <SignIn />,
        },
        {
          path: ':type',
          element: <SignIn />,
        },
      ],
    },
    {
      path: '/register',
      element: <SignUp />,
    },
    {
      path: '/register/student',
      element: <SignUpStudent />,
    },
    {
      path: '/register/teacher',
      element: <SignUpTeacher />,
    },
    {
      path: '/verify-email/:role',
      element: <EmailVerification />,
    },
    {
      path: '/activate/:id/:token',
      element: <ActivateAccount />,
    },
    {
      path: '/forgot',
      element: <ForgotPassword />,
    },
    {
      path: ':role/password_reset/:token',
      element: <ForgotPassword />,
    },
    {
      path: '/logout',
      element: <Logout />,
    },
  ],
}

export default AuthRoutes
