import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import { getPrivacyPoliciesSuccess, getPrivacyPoliciesFailure } from './actions'

export function* get_privacy_policies(): any {
  try {
    const response = yield call(api.get, `/privacy_policies/latest`)
    yield put(getPrivacyPoliciesSuccess(response.data))
  } catch (error) {
    yield put(getPrivacyPoliciesFailure(error))
  }
}
