import { Reducer } from 'redux'
import { ContentsState, ContentsTypes } from './types'

const INITIAL_STATE: ContentsState = {
  data: [],
  loading: false,
  error: false,
}

const reducer: Reducer<ContentsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContentsTypes.GET_CONTENT_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
      }
    case ContentsTypes.GET_CONTENT_SUCCESS:
      return {
        ...state,
        data: action.payload.data['hydra:member'],
        loading: false,
        error: false,
      }
    case ContentsTypes.GET_CONTENT_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default reducer
