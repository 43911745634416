import * as React from 'react'
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  Paper,
  Divider,
  Stack,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import { ReactComponent as InstagramIcon } from '../../../assets/layout/InstagramIcon.svg'
import { ReactComponent as FacebookIcon } from '../../../assets/layout/FacebookIcon.svg'

import { useAuth } from '../../../hooks'
import { themeApp } from '../../../themes'

const pages = [
  {
    title: 'ACESSO DO ALUNO',
    href: '/login/student',
  },
  {
    title: 'ACESSO DO PROFESSOR',
    href: '/login/teacher',
  },
]

const Item = styled(Paper)(() => ({
  display: 'flex',
  backgroundColor: 'transparent',
}))

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElNav(event.currentTarget)

  const handleCloseNavMenu = () => setAnchorElNav(null)

  const navigate = useNavigate()
  const { signed, user, logout, role } = useAuth()

  return (
    <AppBar position='fixed' color='secondary'>
      <Container maxWidth='xl' sx={{ padding: '0px' }}>
        <Toolbar disableGutters>
          {/** Desktop Version */}
          <Typography
            variant='h6'
            noWrap
            component='a'
            href='/home'
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              letterSpacing: 0,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              divider={
                <Divider
                  orientation='vertical'
                  flexItem
                  color='#5d6f7b'
                  sx={{
                    mr: '22px !important',
                    ml: '22px !important',
                  }}
                />
              }
            >
              <Item>
                <span
                  style={{
                    marginRight: '10px',
                    fontWeight: 'bold',
                    color: '#f3f4f5',
                    fontSize: '24px',
                  }}
                >
                  EWA
                </span>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#f3f4f5',
                    fontSize: '15px',
                    fontWeight: 100,
                  }}
                >
                  AULAS PARTICULARES DE <b style={{ marginLeft: 9 }}>INGLÊS</b>
                </span>
              </Item>
              <Item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  fontWeight: 500,
                  color: '#5d6f7b',
                  boxShadow: 'none',
                }}
              >
                <span style={{ marginRight: '10px' }}>Siga-nos:</span>
                <FacebookIcon
                  height='14px'
                  width='14px'
                  fill='#FFFFFF'
                  fillRule='nonzero'
                  fillOpacity={0.25098}
                  style={{ marginRight: '5px' }}
                />
                <InstagramIcon
                  height='14px'
                  width='14px'
                  fill='#FFFFFF'
                  fillRule='nonzero'
                  fillOpacity={0.25098}
                />
              </Item>
            </Stack>
          </Typography>

          {/** Mobile Version */}
          <Typography
            variant='h5'
            noWrap
            component='a'
            href='/home'
            sx={{
              ml: 2,
              display: { xs: 'flex', md: 'none' },
              alignItems: 'center',
              flexGrow: 1,
              fontSize: 14,
              letterSpacing: 0,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <span style={{ marginRight: 10, fontWeight: 'bold' }}>EWA</span>
            <span style={{ fontSize: 10 }}>
              AULAS PARTICULARES DE <b>INGLÊS</b>
            </span>
          </Typography>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            {signed ? (
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem disabled>
                  <Typography textAlign='center'>Olá, {user ? user.name : null}!</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    navigate(
                      role === 'student'
                        ? '/restricted-area/student/'
                        : '/restricted-area/teacher/',
                    )
                  }
                >
                  <Typography textAlign='center'>
                    {role === 'student' ? 'Área Restrita do Aluno' : 'Área Restrita do Professor'}
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => logout()}>
                  <Typography textAlign='center'>Sair</Typography>
                </MenuItem>
              </Menu>
            ) : (
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem key={index} onClick={() => navigate(page.href)}>
                    <Typography textAlign='center'>{page.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: 'none',
                md: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                gap: 10,
                marginRight: 10,
              },
            }}
          >
            {signed ? (
              <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                <Typography>Olá, {user ? user.name : null}!</Typography>
                <Button
                  onClick={() =>
                    navigate(
                      role === 'student'
                        ? '/restricted-area/student/'
                        : '/restricted-area/teacher/',
                    )
                  }
                  variant='outlined'
                  sx={{
                    my: 2,
                    color: '#e4e7e9',
                    display: 'block',
                    border: '1px solid #e4e7e9',
                    borderRadius: '20px',
                    padding: '5px 15px',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    '&:hover': {
                      backgroundColor: `${themeApp.hoverColor}`,
                      border: `1px solid #FFFFFF`,
                    },
                  }}
                >
                  {role === 'student' ? 'Área Restrita do Aluno' : 'Área Restrita do Professor'}
                </Button>
                <Button
                  onClick={() => logout()}
                  variant='text'
                  color='error'
                  endIcon={<HighlightOffIcon />}
                >
                  Sair
                </Button>
              </Box>
            ) : (
              pages.map((page, index) => {
                return (
                  <Button
                    key={index}
                    onClick={() => navigate(page.href)}
                    variant='outlined'
                    sx={{
                      my: 2,
                      color: '#e4e7e9',
                      display: 'block',
                      border: '1px solid #e4e7e9',
                      borderRadius: '20px',
                      padding: '6px 40px',
                      fontSize: '14px',
                      fontWeight: 'normal',
                      '&:hover': {
                        backgroundColor: `${themeApp.hoverColor}`,
                        border: `1px solid #FFFFFF`,
                      },
                    }}
                  >
                    {page.title}
                  </Button>
                )
              })
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header
