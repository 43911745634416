import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks'

interface ProtectedRouteProps {
  authenticationPath?: string
  isRolePermission?: boolean
  rolePermission?: string
  isAccountComplete?: boolean
  children?: JSX.Element
}

const ProtectedRoute = ({
  authenticationPath,
  rolePermission,
  isRolePermission,
  children,
}: ProtectedRouteProps) => {
  const auth = useAuth()
  const navigate = useNavigate()
  let location = useLocation()

  const userHasRequiredRole = auth.role.includes(rolePermission)

  React.useEffect(() => {
    if (!auth.signed)
      navigate('/login', {
        state: {
          from: location,
        },
      })

    /*
    const urlTo = isRolePermission
      ? rolePermission || auth.role !== 'student'
        ? '/restricted-area/student'
        : '/restricted-area/teacher'
      : authenticationPath
      ? authenticationPath
      : '/login'
 */

    let urlTo = authenticationPath
    if (isRolePermission) {
      if (rolePermission === 'student' && auth.role === 'student') {
        urlTo = '/restricted-area/student'
      } else {
        urlTo = '/restricted-area/teacher'
      }
    }

    if (auth.signed && !userHasRequiredRole) {
      navigate(urlTo, {
        state: {
          from: location,
        },
      })
    }
  }, [auth.signed, auth.role, rolePermission])
  return children
}
export default ProtectedRoute
