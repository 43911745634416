import { Reducer } from 'redux'
import { FAQState, FAQTypes } from './types'

const INITIAL_STATE: FAQState = {
  dataAll: [],
  error: null,
  isError: false,
  isLoading: false,
  totalItems: 0,
}

const reducer: Reducer<FAQState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FAQTypes.GET_ALL_FAQ_REQUEST:
      return {
        ...state,
        dataAll: [],
        error: null,
        isLoading: true,
        isError: false,
        totalItems: 0,
      }
    case FAQTypes.GET_ALL_FAQ_SUCCESS:
      return {
        ...state,
        dataAll: action.payload.data['hydra:member'],
        error: null,
        isLoading: false,
        isError: false,
        totalItems: action.payload.data['hydra:totalItems'],
      }
    case FAQTypes.GET_ALL_FAQ_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isError: true,
        totalItems: 0,
      }
    default:
      return state
  }
}

export default reducer
