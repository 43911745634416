import { Reducer } from 'redux'
import { SpecializationState, SpecializationTypes } from './types'

const INITIAL_STATE: SpecializationState = {
  data: [],
  error: '',
  isError: false,
  isLoading: false,
  totalItems: 0,
}

const reducer: Reducer<SpecializationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // LIST_SPECIALIZATIONS
    case SpecializationTypes.LIST_SPECIALIZATIONS_REQUEST:
      return {
        ...state,
        data: [],
        error: '',
        isLoading: true,
        isError: false,
        totalItems: 0,
      }
    case SpecializationTypes.LIST_SPECIALIZATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data['hydra:member'],
        error: '',
        isLoading: false,
        isError: false,
        totalItems: action.payload.data['hydra:totalItems'],
      }
    case SpecializationTypes.LIST_SPECIALIZATIONS_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload.error,
        isLoading: false,
        isError: true,
        totalItems: 0,
      }

    default:
      return state
  }
}

export default reducer
