import { Page } from '../../../types/Page'
import { User } from '../../../types/User'

/**
 * Action Types
 */
export enum SearchTypes {
  SEARCH_REQUEST_INDEX = '@search/request/index',
  SEARCH_SUCCESS_INDEX = '@search/success/index',
  SEARCH_FAILURE_INDEX = '@search/failure/index',

  SEARCH_HOME_REQUEST = '@search/home/request',
  SEARCH_REQUEST = '@search/request',
  SEARCH_SUCCESS = '@search/success',
  SEARCH_FAILURE = '@search/failure',

  CLASS_VALUES = '@search/get_class_values',
}

/**
 * Data Types
 */
export interface ClassValues {
  min: number
  max: number
}

/**
 * State type
 */
export interface SearchState {
  readonly dataAll: Array<User>
  readonly calendar: Array<Date>
  readonly hours: Array<string>
  readonly specifications: Array<string>
  readonly native: boolean
  readonly resident: boolean
  readonly classValues: ClassValues
  readonly countProfessionalsFound: number
  readonly page: Page
  readonly totalItems: number
  readonly isLoading: boolean
}
