import { Reducer } from 'redux'
import { TeacherState, TeacherTypes } from './types'

const INITIAL_STATE: TeacherState = {
  data: [],
  teacher: null,
  teacherId: null,
  error: false,
  loading: false,
}

const reducer: Reducer<TeacherState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TeacherTypes.REQUEST_GET_TEACHER_ALL:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
      }
    case TeacherTypes.SUCCESS_GET_TEACHER_ALL:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      }
    case TeacherTypes.FAILURE_GET_TEACHER_ALL:
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
      }
    case TeacherTypes.REQUEST_GET_TEACHER:
      return {
        ...state,
        teacher: null,
        teacherId: action.payload.teacherId,
        loading: true,
        error: false,
      }
    case TeacherTypes.SUCCESS_GET_TEACHER:
      return {
        ...state,
        teacher: action.payload.teacher,
        loading: false,
        error: false,
      }
    case TeacherTypes.FAILURE_GET_TEACHER:
      return {
        ...state,
        teacher: null,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default reducer
