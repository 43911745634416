import { TermsOfUse as TermsOfUseBase } from './../../../types/TermsOfUse'
/**
 * Action Types
 */
export enum TermsOfUseTypes {
  GET_TERMS_OF_USE_REQUEST = '@privacyPolicies/get/request',
  GET_TERMS_OF_USE_SUCCESS = '@privacyPolicies/get/success',
  GET_TERMS_OF_USE_FAILURE = '@privacyPolicies/get/failure',
}

/**
 * Data Types
 */
export interface TermsOfUse extends TermsOfUseBase {}

/**
 * State type
 */
export interface TermsOfUseState {
  readonly data: TermsOfUse[]
  readonly error: Error
  readonly isError: boolean
  readonly isLoading: boolean
}
