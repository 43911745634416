const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: '',
  defaultPath: '/home',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  app: {
    name: process.env.REACT_APP_WEBSITE_NAME,
    description: '',
    keyboards: '',
  },
  layout: {
    header: {
      main: '#173042',
    },
  },
}

export default config
