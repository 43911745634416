import { action } from 'typesafe-actions'
import { Error } from '../../../types/Error'
import { types } from '../index'
import { Testimonials } from './types'

/**
 * It returns an action object with a type of GET_ALL_TESTIMONIALS_REQUEST
 */
export const getAllTestimonialsRequest = (itemsPerPage = 6) =>
  action(types.TestimonialsTypes.GET_ALL_TESTIMONIALS_REQUEST, { itemsPerPage })

/**
 * It returns an action object with a type of GET_ALL_TESTIMONIALS_SUCCESS and a
 * payload of data
 * @param {Testimonials[]} data - Testimonials[] - this is the data that will be
 * passed to the reducer.
 */
export const getAllTestimonialsSuccess = (data: Testimonials[]) =>
  action(types.TestimonialsTypes.GET_ALL_TESTIMONIALS_SUCCESS, { data })

/**
 * It returns an action object with a type of GET_ALL_TESTIMONIALS_FAILURE and an
 * error property
 * @param {Error} error - Error - this is the error object that is returned from
 * the API call.
 */
export const getAllTestimonialsFailure = (error: Error) =>
  action(types.TestimonialsTypes.GET_ALL_TESTIMONIALS_FAILURE, { error })
