import { Settings as SettingsBase } from '../../../types/Settings'

/**
 * Action Types
 */
export enum SettingsTypes {
  GET_ALL_SETTINGS_REQUEST = '@settings/request/all',
  GET_ALL_SETTINGS_SUCCESS = '@settings/success/all',
  GET_ALL_SETTINGS_FAILURE = '@settings/failure/all',
}

/**
 * Data Types
 */
export interface Settings extends SettingsBase {}

/**
 * State type
 */
export interface SettingsState {
  readonly settings: Settings[]
}
