import React from 'react'
import { Outlet } from 'react-router-dom'
import { SuperSEO } from 'react-super-seo'

// Layout
import Header from './Header'
import Footer from './Footer'

// Imports Internal
import { useAppDispatch, useIsMounted } from '../../hooks'

// Redux
import * as SettingsActions from '../../store/ducks/Settings/actions'

const MainLayout = () => {
  const isMounted = useIsMounted()
  const appDispatch = useAppDispatch()

  React.useEffect(() => {
    if (isMounted) {
      appDispatch(SettingsActions.getAllSettingsRequest())
    }
  }, [isMounted])

  return (
    <>
      {process.env.NODE_ENV === 'production' ? (
        <SuperSEO
          title={process.env.REACT_APP_WEBSITE_NAME}
          description={`${process.env.REACT_APP_WEBSITE_NAME}`}
          lang='pt_BR'
          charset='UTF-8'
        />
      ) : null}
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}
export default MainLayout
