import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import { getTermsOfUseSuccess, getTermsOfUseFailure } from './actions'

export function* get_terms_of_use(): any {
  try {
    const response = yield call(api.get, `/terms/latest`)
    yield put(getTermsOfUseSuccess(response.data))
  } catch (error) {
    yield put(getTermsOfUseFailure(error))
  }
}
