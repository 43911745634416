import { Reducer } from 'redux'
import { InvitesState, InvitesTypes } from './types'

const INITIAL_STATE: InvitesState = {
  data: null,
  dataAll: [],
  error: null,
  isError: false,
  isLoading: false,
  totalItems: 0,
}

const reducer: Reducer<InvitesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InvitesTypes.LIST_INVITES_REQUEST:
      return {
        ...state,
        dataAll: [],
        error: null,
        isLoading: true,
        isError: false,
        totalItems: 0,
      }
    case InvitesTypes.LIST_INVITES_SUCCESS:
      return {
        ...state,
        dataAll: action.payload.data['hydra:member'],
        error: null,
        isLoading: false,
        isError: false,
        totalItems: action.payload.data['hydra:totalItems'],
      }
    case InvitesTypes.LIST_INVITES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isError: true,
        totalItems: 0,
      }

    case InvitesTypes.SEND_INVITES_REQUEST:
      return {
        ...state,
        data: action.payload.invite,
        error: null,
        isLoading: true,
        isError: false,
      }
    case InvitesTypes.SEND_INVITES_SUCCESS:
      return {
        ...state,
        data: null,
        error: null,
        isLoading: false,
        isError: false,
      }
    case InvitesTypes.SEND_INVITES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isError: true,
      }
    default:
      return state
  }
}

export default reducer
