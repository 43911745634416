import { Reducer } from 'redux'
import { NotificationTypes, NotificationState } from './types'

const INITIAL_STATE: NotificationState = {
  title: 'Informação',
  message: null,
  severity: 'info',
  autoHideDuration: 4000,
  isOpen: false,
}

const reducer: Reducer<NotificationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NotificationTypes.NOTIFICATION_OPEN:
      return {
        ...state,
        title: action.payload.title,
        message: action.payload.message.replace(/\n/g, '<br />'),
        severity: action.payload.severity,
        autoHideDuration: action.payload.autoHideDuration ?? 4000,
        anchorOrigin: action.payload.anchorOrigin,
        variant: action.payload.variant,
        isOpen: true,
      }
    case NotificationTypes.NOTIFICATION_CLOSE:
      return {
        ...state,
        title: null,
        message: null,
        isOpen: false,
      }
    default:
      return state
  }
}

export default reducer
