import { Testimonials as TestimonialsBase } from './../../../types/Testimonials'
/**
 * Action Types
 */
export enum TestimonialsTypes {
  GET_ALL_TESTIMONIALS_REQUEST = '@testimonials/get_all/request',
  GET_ALL_TESTIMONIALS_SUCCESS = '@testimonials/get_all/success',
  GET_ALL_TESTIMONIALS_FAILURE = '@testimonials/get_all/failure',
}

/**
 * Data Types
 */
export interface Testimonials extends TestimonialsBase {}

/**
 * State type
 */
export interface TestimonialsState {
  readonly dataAll: Testimonials[]
  readonly error: Error
  readonly isError: boolean
  readonly isLoading: boolean
  readonly totalItems: number
}
