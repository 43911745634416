import { PrivacyPolicies as PrivacyPoliciesBase } from './../../../types/PrivacyPolicies'
/**
 * Action Types
 */
export enum PrivacyPoliciesTypes {
  GET_PRIVACY_POLICIES_REQUEST = '@privacyPolicies/get/request',
  GET_PRIVACY_POLICIES_SUCCESS = '@privacyPolicies/get/success',
  GET_PRIVACY_POLICIES_FAILURE = '@privacyPolicies/get/failure',
}

/**
 * Data Types
 */
export interface PrivacyPolicies extends PrivacyPoliciesBase {}

/**
 * State type
 */
export interface PrivacyPoliciesState {
  readonly data: PrivacyPolicies
  readonly error: Error
  readonly isError: boolean
  readonly isLoading: boolean
}
