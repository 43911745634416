import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import history from '../../../routes/history'
import { searchIndexSuccess, searchIndexFailure, searchFailure, searchSuccess } from './actions'
import { SearchTypes } from './types'

export function* searchIndex(data: any): any {
  try {
    const response = yield call(
      api.get,
      `/teachers?itemsPerPage=${data.payload.itemsPerPage}&page=${data.payload.page}`,
    )
    const classValuesResponse = yield call(api.get, '/schedule_prices')

    yield put(
      searchIndexSuccess(
        response.data['hydra:member'],
        {
          min: classValuesResponse.data.min,
          max: classValuesResponse.data.max,
        },
        response.data['hydra:totalItems'],
        data.payload.itemsPerPage,
        response.data['hydra:view'],
      ),
    )
  } catch (error) {
    yield put(searchIndexFailure(error))
  }
}

export function* search(data: any): any {
  const dth = data.payload.hours.reduce((a, b) => {
    switch (b) {
      case 'morning':
        return data.payload.calendar.reduce((c, d) => {
          c.push(`schedules[]=${d} 06:00, ${d} 12:00`)
          return c
        }, [])
      case 'afternoon':
        return data.payload.calendar.reduce((c, d) => {
          c.push(`schedules[]=${d} 12:00, ${d} 18:00`)
          return c
        }, [])
      case 'night':
        return data.payload.calendar.reduce((c, d) => {
          c.push(`schedules[]=${d} 18:00, ${d} 23:00`)
          return c
        }, [])
      case 'dawn':
        return data.payload.calendar.reduce((c, d) => {
          c.push(`schedules[]=${d} 00:00, ${d} 06:00`)
          return c
        }, [])
    }
  }, [])

  const dt = data.payload.calendar.reduce((c, d) => {
    c.push(`schedules[]=${d}`)
    return c
  }, [])

  const schedules = dth.length > 0 ? dth : dt

  let url = Array<string>()

  if (data.payload.calendar && data.payload.calendar.length > 0) url.push(schedules.join('&'))
  if (data.payload.specifications && data.payload.specifications.length > 0)
    url.push(`specializations[]=${data.payload.specifications.join(',')}`)
  if (data.payload.native) url.push(`nativeSpeaker=${data.payload.native}`)
  if (data.payload.resident) url.push(`resident=${data.payload.resident}`)
  if (data.payload.classValues)
    url.push(`classValue[between]=${data.payload.classValues.min}..${data.payload.classValues.max}`)
  if (data.payload.teachingModalities && data.payload.teachingModalities > 0)
    url.push(`teachingModalities.modality[]=${data.payload.teachingModalities.join(',')}`)

  try {
    const response = yield call(
      api.get,
      `/teachers?itemsPerPage=${data.payload.itemsPerPage}&page=${data.payload.page}&${url.join(
        '&',
      )}`,
    )
    const classValuesResponse = yield call(api.get, '/schedule_prices')

    yield put(
      searchSuccess(
        response.data['hydra:member'],
        {
          min: classValuesResponse.data.min,
          max: classValuesResponse.data.max,
        },
        response.data['hydra:totalItems'],
        data.payload.itemsPerPage,
        response.data['hydra:view'],
      ),
    )
  } catch (error) {
    yield put(searchFailure(error))
  }
}

export function* searchHome(data: any): any {
  try {
    yield call(search, {
      type: SearchTypes.SEARCH_REQUEST,
      payload: data.payload,
    })
    yield call(history.push, '/buscar/home', {
      calendar: data.payload.calendar,
      hours: data.payload.hours,
      specifications: data.payload.specifications,
      native: data.payload.native,
      resident: data.payload.resident,
      teachingModalities: data.payload.teachingModalities,
      classValues: data.payload.classValues,
      itemsPerPage: data.payload.itemsPerPage,
      page: data.payload.page,
    })
  } catch (error) {
    console.log(error)
  }
}
