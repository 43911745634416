import { Reducer } from 'redux'
import { OrdersState, OrdersTypes } from './types'

const INITIAL_STATE: OrdersState = {
  dataAll: [],
  data: null,

  dataAvailable: '',
  manageOrder: null,

  loading: false,

  isSuccess: false,
  isError: false,
  error: null,

  page: null,
  totalItems: 0,
}

const reducer: Reducer<OrdersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrdersTypes.NEW_ORDER_REQUEST:
      return {
        ...state,
        dataAvailable: action.payload.dataAvailable,
        loading: true,
        isError: false,
        error: null,
      }
    case OrdersTypes.NEW_ORDER_SUCCESS:
      return {
        ...state,
        dataAvailable: '',
        data: action.payload.data,
        loading: false,
        isError: false,
        error: null,
      }
    case OrdersTypes.NEW_ORDER_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        isError: false,
        error: action.payload.error,
      }

    case OrdersTypes.GET_ALL_INIT:
    case OrdersTypes.GET_CLASSES_TEACHER_REQUEST:
    case OrdersTypes.GET_ALL_REQUEST:
      return {
        ...state,
        dataAll: [],
        loading: true,
        isError: false,
        error: null,
      }
    case OrdersTypes.GET_CLASSES_TEACHER_SUCCESS:
    case OrdersTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        dataAll: action.payload.orders,
        loading: false,
        isError: false,
        error: null,
        page: action.payload.page,
        totalItems: action.payload.totalItems,
      }
    case OrdersTypes.GET_CLASSES_TEACHER_FAILURE:
    case OrdersTypes.GET_ALL_FAILURE:
      return {
        ...state,
        dataAll: [],
        loading: false,
        isError: false,
        error: action.payload.error,
      }

    case OrdersTypes.ACCEPT_ORDER_REQUEST:
    case OrdersTypes.DENY_ORDER_REQUEST:
    case OrdersTypes.CANCEL_ORDER_REQUEST:
    case OrdersTypes.CANCEL_PENDING_ORDER_REQUEST:
      return {
        ...state,
        manageOrder: null,
        error: null,
        loading: true,
        isSuccess: false,
        isError: false,
      }

    case OrdersTypes.ACCEPT_ORDER_SUCCESS:
      return {
        ...state,
        manageOrder: {
          accept: {
            orderId: action.payload.orderId,
            order: action.payload.order,
          },
        },
        error: null,
        loading: false,
        isSuccess: true,
        isError: false,
      }
    case OrdersTypes.DENY_ORDER_SUCCESS:
      return {
        ...state,
        manageOrder: {
          deny: {
            orderId: action.payload.orderId,
            order: action.payload.order,
          },
        },
        error: null,
        loading: false,
        isSuccess: true,
        isError: false,
      }
    case OrdersTypes.CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        manageOrder: {
          requestRefund: {
            orderId: action.payload.orderId,
          },
        },
        error: null,
        loading: false,
        isSuccess: true,
        isError: false,
      }
    case OrdersTypes.CANCEL_PENDING_ORDER_SUCCESS:
      return {
        ...state,
        manageOrder: {
          cancel_pending: {
            orderId: action.payload.orderId,
          },
        },
        error: null,
        loading: false,
        isSuccess: true,
        isError: false,
      }

    case OrdersTypes.ACCEPT_ORDER_FAILURE:
    case OrdersTypes.DENY_ORDER_FAILURE:
    case OrdersTypes.CANCEL_ORDER_FAILURE:
    case OrdersTypes.CANCEL_PENDING_ORDER_FAILURE:
      return {
        ...state,
        manageOrder: null,
        error: action.payload.error,
        loading: false,
        isSuccess: false,
        isError: true,
      }

    default:
      return state
  }
}

export default reducer
