import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import { getListModalitySuccess, getListModalityFailure } from './actions'

export function* get_list_modalities(): any {
  try {
    const response = yield call(api.get, '/modalities')

    yield put(getListModalitySuccess(response.data))
  } catch (error) {
    yield put(getListModalityFailure(error))
  }
}
