import { action } from 'typesafe-actions'
import { Error } from '../../../types/Error'
import { Invites } from '../../../types/Invites'
import { types } from '../index'

export const getListInvitesRequest = () => action(types.InvitesTypes.LIST_INVITES_REQUEST)

export const getListInvitesSuccess = (data: Invites[]) =>
  action(types.InvitesTypes.LIST_INVITES_SUCCESS, { data })

export const getListInvitesFailure = (error: Error) =>
  action(types.InvitesTypes.LIST_INVITES_FAILURE, { error })

export const sendInvitesRequest = (invite: Invites) =>
  action(types.InvitesTypes.SEND_INVITES_REQUEST, { invite })

export const sendInvitesSuccess = () => action(types.InvitesTypes.SEND_INVITES_SUCCESS)

export const sendInvitesFailure = (error: Error) =>
  action(types.InvitesTypes.SEND_INVITES_FAILURE, { error })
