import { action } from 'typesafe-actions'
import { SearchTypes, ClassValues } from './types'
import { Page } from '../../../types/Page'
import { User } from '../../../types/User'

export const searchIndexRequest = (itemsPerPage = 10, page = 1) =>
  action(SearchTypes.SEARCH_REQUEST_INDEX, { itemsPerPage, page })
export const searchIndexSuccess = (
  dataAll: User[],
  classValues: ClassValues,
  countProfessionalsFound: number,
  itemsPerPage: number,
  page: Page,
) =>
  action(SearchTypes.SEARCH_SUCCESS_INDEX, {
    dataAll,
    classValues,
    countProfessionalsFound,
    itemsPerPage,
    page,
  })
export const searchIndexFailure = (error: any) =>
  action(SearchTypes.SEARCH_FAILURE_INDEX, { ...error })

export const searchRequest = (
  calendar: Array<string>,
  hours: Array<string>,
  specifications: Array<string>,
  native: boolean,
  resident: boolean,
  teachingModalities: Array<string>,
  classValues: ClassValues,
  itemsPerPage = 10,
  page = 1,
) =>
  action(SearchTypes.SEARCH_REQUEST, {
    calendar,
    hours,
    specifications,
    native,
    resident,
    teachingModalities,
    classValues,
    itemsPerPage,
    page,
  })

export const searchHomeRequest = (
  calendar: Array<string>,
  hours: Array<string>,
  specifications: Array<string>,
  native: boolean,
  resident: boolean,
  teachingModalities: Array<string>,
  classValues: ClassValues,
  itemsPerPage = 10,
  page = 1,
) =>
  action(SearchTypes.SEARCH_HOME_REQUEST, {
    calendar,
    hours,
    specifications,
    native,
    resident,
    teachingModalities,
    classValues,
    itemsPerPage,
    page,
  })

export const searchSuccess = (
  dataAll: User[],
  classValues: ClassValues,
  countProfessionalsFound: number,
  itemsPerPage: number,
  page: Page,
) =>
  action(SearchTypes.SEARCH_SUCCESS, {
    dataAll,
    classValues,
    countProfessionalsFound,
    itemsPerPage,
    page,
  })

export const searchFailure = (error: any) => action(SearchTypes.SEARCH_FAILURE, { ...error })
