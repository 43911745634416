import { Moment } from 'moment'
import { Error } from '../../../types/Error'
import { Order } from '../Orders/types'
import { Teacher } from '../Teacher/types'
import { Transaction } from '../../../types/Transaction'
import { Schedule as BaseSchedule } from '../../../types/Schedule'
import { MySchedule } from '../../../types/MySchedule'
import { DiscountCoupon } from '../../../types/DiscountCoupon'

/**
 * Action Types
 */
export enum ScheduleTypes {
  SCHEDULE_INIT = '@schedule/init',
  SCHEDULE_REQUEST = '@schedule/request',

  GET_ALL_SCHEDULE_INIT = '@schedule/get_all/init',
  GET_ALL_SCHEDULE_REQUEST = '@schedule/get_all/request',
  GET_ALL_SCHEDULE_SUCCESS = '@schedule/get_all/success',
  GET_ALL_SCHEDULE_FAILURE = '@schedule/get_all/failure',

  MY_SCHEDULE_INIT = '@my_schedule/init',
  MY_SCHEDULE_REQUEST = '@my_schedule/request',
  MY_SCHEDULE_SUCCESS = '@my_schedule/success',
  MY_SCHEDULE_FAILURE = '@my_schedule/failure',

  NEW_SCHEDULE_AVAILABLE_INIT = '@schedule/new_schedule_available/init',
  NEW_SCHEDULE_AVAILABLE_REQUEST = '@schedule/new_schedule_available/request',
  NEW_SCHEDULE_AVAILABLE_SUCCESS = '@schedule/new_schedule_available/success',
  NEW_SCHEDULE_AVAILABLE_FAILURE = '@schedule/new_schedule_available/failure',

  DELETE_SCHEDULE_AVAILABLE_REQUEST = '@schedule/delete_schedule_available/request',
  DELETE_SCHEDULE_AVAILABLE_SUCCESS = '@schedule/delete_schedule_available/success',
  DELETE_SCHEDULE_AVAILABLE_FAILURE = '@schedule/delete_schedule_available/failure',

  PURCHASE_REQUEST = '@purchase/request',
  PURCHASE_SUCCESS = '@purchase/success',
  PURCHASE_FAILURE = '@purchase/failure',

  PAYMENT_REQUEST = '@payment/request',
  PAYMENT_SUCCESS = '@payment/success',
  PAYMENT_FAILURE = '@payment/failure',
  PAYMENT_TRY_AGAIN = '@payment/try_again',
  PAYMENT_WAITING = '@payment/waiting_payment',

  PAYMENT_TRANSACTION_SUCCESS = '@payment/transaction/success',
  PAYMENT_TRANSACTION_FAILURE = '@payment/transaction/failure',

  DISCOUNTS_ORDERS_INIT = '@payment/discount_orders/init',
  DISCOUNTS_ORDERS_REQUEST = '@payment/discount_orders/request',
  DISCOUNTS_ORDERS_SUCCESS = '@payment/discount_orders/success',
  DISCOUNTS_ORDERS_FAILURE = '@payment/discount_orders/failure',

  DISCOUNTS_COUPON_INIT = '@payment/discount_coupon/init',
  DISCOUNTS_COUPON_REQUEST = '@payment/discount_coupon/request',
  DISCOUNTS_COUPON_SUCCESS = '@payment/discount_coupon/success',
  DISCOUNTS_COUPON_FAILURE = '@payment/discount_coupon/failure',

  CAlCULATE_INSTALLMENTS_REQUEST = '@payment/calculate_installments/request',
  CAlCULATE_INSTALLMENTS_SUCCESS = '@payment/calculate_installments/success',
  CAlCULATE_INSTALLMENTS_FAILURE = '@payment/calculate_installments/failure',

  CONFIRM_PAYMENT_REQUEST = '@confirm_payment/request',
  CONFIRM_PAYMENT_SUCCESS = '@confirm_payment/success',
  CONFIRM_PAYMENT_FAILURE = '@confirm_payment/failure',
}

export enum StateProcess {
  SCHEDULE_REQUEST = 'initial',
  SCHEDULE_PURCHASE = 'purchase',
  SCHEDULE_PAYMENT = 'payment',
  SCHEDULE_CONFIRM_PAYMENT = 'confirm_payment',
}

/**
 * Data Types
 */
export interface Schedule extends BaseSchedule {}

export interface Payment {
  schedule: string
  paymentMethod: string
  creditCard?: {
    number: string
    name: string
    expiryDate: string
    cvv: string
    installments: number
  }
}

export interface Installments {
  [key: string]: {
    installment: number
    amount: number
    installment_amount: number
  }
}

interface CalculateInstallments {
  amount: number
  installments: Installments[]
  isLoading?: boolean
}

interface ScheduleAvailable {
  schedule?: Schedule
  error?: Error
  isSuccess?: boolean
  isLoading?: boolean
  isError?: boolean
}

/**
 * State type
 */
export interface ScheduleState {
  // GET_ALL
  readonly dataAll: Schedule[] | MySchedule[]

  readonly teacher: Teacher

  readonly scheduleId: string
  readonly schedule: Schedule
  readonly dateTimeAvailable: Moment

  // Schedule Available
  readonly newScheduleAvailable: ScheduleAvailable
  readonly deleteScheduleAvailable: ScheduleAvailable

  // Payment
  readonly payment: Payment
  readonly stateProcess: StateProcess
  readonly calculateInstallments: CalculateInstallments

  // Order
  readonly order: Order
  readonly discountCoupon: DiscountCoupon
  readonly discountOrders: string[]
  readonly orderValue: number
  readonly availableCredit: number
  readonly transaction: Transaction

  readonly isLoading: boolean

  readonly error: Error
  readonly isError: boolean
  readonly isTransactionError: boolean

  readonly isDelete: boolean
}
