import { useRoutes } from 'react-router-dom'

import config from '../config'
import AuthRoutes from './AuthRoutes'
import MainRoutes from './MainRoutes'

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes(), AuthRoutes], config.basename)
}
