import { Reducer } from 'redux'
import { ScheduleTypes, ScheduleState, StateProcess, Installments } from './types'

const INITIAL_STATE: ScheduleState = {
  dataAll: [],
  teacher: null,
  payment: null,

  scheduleId: null,
  schedule: null,
  dateTimeAvailable: null,

  calculateInstallments: null,
  stateProcess: StateProcess.SCHEDULE_PURCHASE,
  order: null,
  transaction: null,

  discountCoupon: null,
  discountOrders: [],
  orderValue: 0,
  availableCredit: 0,

  newScheduleAvailable: null,
  deleteScheduleAvailable: null,

  isLoading: false,
  error: null,
  isDelete: false,
  isError: false,
  isTransactionError: false,
}

const reducer: Reducer<ScheduleState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ScheduleTypes.GET_ALL_SCHEDULE_INIT: {
      return {
        ...state,
        dataAll: [],
        isLoading: false,
        isError: false,
      }
    }
    case ScheduleTypes.GET_ALL_SCHEDULE_REQUEST: {
      return {
        ...state,
        dataAll: [],
        isLoading: true,
        isError: false,
      }
    }
    case ScheduleTypes.GET_ALL_SCHEDULE_SUCCESS: {
      return { ...state, dataAll: action.payload.schedule, isLoading: false, isError: false }
    }
    case ScheduleTypes.GET_ALL_SCHEDULE_FAILURE: {
      return {
        ...state,
        dataAll: [],
        error: action.payload.error,
        isLoading: false,
        isError: true,
      }
    }

    case ScheduleTypes.MY_SCHEDULE_INIT: {
      return {
        ...state,
        dataAll: [],
        isLoading: false,
        isError: false,
      }
    }
    case ScheduleTypes.MY_SCHEDULE_REQUEST: {
      return {
        ...state,
        dataAll: [],
        isLoading: true,
        isError: false,
      }
    }
    case ScheduleTypes.MY_SCHEDULE_SUCCESS: {
      return {
        ...state,
        dataAll: action.payload.mySchedule,
        isLoading: false,
        isError: false,
      }
    }
    case ScheduleTypes.MY_SCHEDULE_FAILURE: {
      return {
        ...state,
        dataAll: [],
        error: action.payload.error,
        isLoading: false,
        isError: true,
      }
    }

    case ScheduleTypes.SCHEDULE_INIT:
      return {
        ...INITIAL_STATE,
      }
    case ScheduleTypes.SCHEDULE_REQUEST:
      return {
        ...state,
        teacher: action.payload.teacher,
        scheduleId: action.payload.scheduleId,
        schedule: null,
        dateTimeAvailable: action.payload.dateTimeAvailable,
        calculateInstallments: null,
        stateProcess: StateProcess.SCHEDULE_REQUEST,
        order: null,
        orderValue: action.payload.teacher.classValue,
        transaction: null,
        payment: null,
        isLoading: false,
        isError: false,
        isTransactionError: false,
      }

    case ScheduleTypes.PURCHASE_REQUEST:
      return {
        ...state,
        stateProcess: StateProcess.SCHEDULE_PURCHASE,
        orderValue: state?.teacher?.classValue,
        discountCoupon: null,
        discountOrders: [],
        isLoading: true,
        isError: false,
        error: null,
      }
    case ScheduleTypes.PURCHASE_SUCCESS:
      return {
        ...state,
        schedule: action.payload.schedule,
        stateProcess: StateProcess.SCHEDULE_PURCHASE,
        payment: null,
        isLoading: false,
        isError: false,
      }
    case ScheduleTypes.PURCHASE_FAILURE:
      return {
        ...state,
        stateProcess: StateProcess.SCHEDULE_PURCHASE,
        isLoading: false,
        isError: true,
      }

    case ScheduleTypes.PAYMENT_REQUEST:
      return {
        ...state,
        stateProcess: StateProcess.SCHEDULE_PAYMENT,
        isLoading: true,
        isError: false,
        error: null,
      }
    case ScheduleTypes.PAYMENT_SUCCESS:
      return {
        ...state,
        order: action.payload.order,
        stateProcess: StateProcess.SCHEDULE_PAYMENT,
        isLoading: false,
        isError: false,
        error: null,
      }
    case ScheduleTypes.PAYMENT_FAILURE:
      return {
        ...state,
        payment: null,
        stateProcess: StateProcess.SCHEDULE_PAYMENT,
        isLoading: false,
        isError: true,
        error: action.payload.error,
      }
    case ScheduleTypes.PAYMENT_TRY_AGAIN: {
      return {
        ...state,
        transaction: null,
        isTransactionError: false,
      }
    }
    case ScheduleTypes.PAYMENT_WAITING:
      return {
        ...state,
        order: action.payload.order,
        transaction: action.payload.order.transaction,
        error: null,
        isLoading: false,
        isError: false,
      }

    case ScheduleTypes.CAlCULATE_INSTALLMENTS_REQUEST: {
      return {
        ...state,
        calculateInstallments: {
          amount: action.payload.amount,
          installments: [],
          isLoading: true,
        },
      }
    }
    case ScheduleTypes.CAlCULATE_INSTALLMENTS_SUCCESS: {
      const array = Object.values<Installments>(action.payload.installments)

      return {
        ...state,
        calculateInstallments: {
          amount: action.payload.amount,
          installments: array,
          isLoading: false,
        },
      }
    }
    case ScheduleTypes.CAlCULATE_INSTALLMENTS_FAILURE: {
      return {
        ...state,
        calculateInstallments: {
          amount: 0,
          installments: [],
          isLoading: true,
        },
      }
    }

    case ScheduleTypes.PAYMENT_TRANSACTION_SUCCESS: {
      return {
        ...state,
        transaction: action.payload.transaction,
        isTransactionError: false,
        isLoading: false,
      }
    }
    case ScheduleTypes.PAYMENT_TRANSACTION_FAILURE: {
      return {
        ...state,
        transaction: action.payload.transaction,
        isTransactionError: true,
        isLoading: false,
      }
    }

    case ScheduleTypes.NEW_SCHEDULE_AVAILABLE_INIT: {
      return {
        ...state,
        newScheduleAvailable: {
          schedule: null,
          error: null,
          isSuccess: false,
          isError: false,
          isLoading: false,
        },
      }
    }
    case ScheduleTypes.NEW_SCHEDULE_AVAILABLE_REQUEST: {
      return {
        ...state,
        newScheduleAvailable: {
          schedule: null,
          error: null,
          isSuccess: false,
          isError: false,
          isLoading: true,
        },
      }
    }
    case ScheduleTypes.NEW_SCHEDULE_AVAILABLE_SUCCESS: {
      return {
        ...state,
        newScheduleAvailable: {
          error: null,
          isSuccess: true,
          isError: false,
          isLoading: false,
        },
      }
    }
    case ScheduleTypes.NEW_SCHEDULE_AVAILABLE_FAILURE: {
      return {
        ...state,
        newScheduleAvailable: {
          error: action.payload.error,
          isSuccess: false,
          isError: true,
          isLoading: false,
        },
      }
    }

    case ScheduleTypes.DELETE_SCHEDULE_AVAILABLE_REQUEST: {
      return {
        ...state,
        deleteScheduleAvailable: {
          schedule: null,
          error: null,
          isSuccess: false,
          isError: false,
          isLoading: true,
        },
      }
    }
    case ScheduleTypes.DELETE_SCHEDULE_AVAILABLE_SUCCESS: {
      return {
        ...state,
        deleteScheduleAvailable: {
          error: null,
          isSuccess: true,
          isError: false,
          isLoading: false,
        },
      }
    }
    case ScheduleTypes.DELETE_SCHEDULE_AVAILABLE_FAILURE: {
      return {
        ...state,
        deleteScheduleAvailable: {
          error: action.payload.error,
          isSuccess: false,
          isError: true,
          isLoading: false,
        },
      }
    }

    case ScheduleTypes.DISCOUNTS_ORDERS_INIT:
      return {
        ...state,
        discountOrders: [],
        orderValue: action.payload.classValue,
        availableCredit: action.payload.availableCredit,
        isError: false,
        error: null,
      }
    case ScheduleTypes.DISCOUNTS_ORDERS_REQUEST:
      return {
        ...state,
        discountOrders: [],
        orderValue: 0,
        availableCreditRest: 0,
        isError: false,
        error: null,
      }
    case ScheduleTypes.DISCOUNTS_ORDERS_SUCCESS:
      return {
        ...state,
        discountOrders: action.payload.discountOrders,
        orderValue: action.payload.orderValue,
        availableCredit: action.payload.availableCredit,
        isError: false,
        error: null,
      }
    case ScheduleTypes.DISCOUNTS_ORDERS_FAILURE:
      return {
        ...state,
        discountOrders: [],
        orderValue: 0,
        availableCreditRest: 0,
        isError: true,
        error: action.payload.error,
      }

    case ScheduleTypes.DISCOUNTS_COUPON_INIT:
      return {
        ...state,
        discountCoupon: null,
        orderValue: state?.teacher?.classValue,
        isError: false,
        error: null,
      }
    case ScheduleTypes.DISCOUNTS_COUPON_REQUEST:
      return {
        ...state,
        discountCoupon: action.payload.discountCoupon,
        orderValue: state?.teacher?.classValue,
        isError: false,
        error: null,
      }
    case ScheduleTypes.DISCOUNTS_COUPON_SUCCESS:
      return {
        ...state,
        discountCoupon: action.payload.discountCoupon,
        orderValue: action.payload.orderValue,
        availableCredit: action.payload.availableCredit,
        isError: false,
        error: null,
      }
    case ScheduleTypes.DISCOUNTS_COUPON_FAILURE:
      return {
        ...state,
        discountCoupon: null,
        orderValue: state?.teacher?.classValue,
        isError: true,
        error: action.payload.error,
      }

    default:
      return state
  }
}

export default reducer
