import { Reducer } from 'redux'
import { TestimonialsState, TestimonialsTypes } from './types'

const INITIAL_STATE: TestimonialsState = {
  dataAll: [],
  error: null,
  isError: false,
  isLoading: false,
  totalItems: 0,
}

const reducer: Reducer<TestimonialsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TestimonialsTypes.GET_ALL_TESTIMONIALS_REQUEST:
      return {
        ...state,
        dataAll: [],
        error: null,
        isLoading: true,
        isError: false,
        totalItems: 0,
      }
    case TestimonialsTypes.GET_ALL_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        dataAll: action.payload.data['hydra:member'],
        error: null,
        isLoading: false,
        isError: false,
        totalItems: action.payload.data['hydra:totalItems'],
      }
    case TestimonialsTypes.GET_ALL_TESTIMONIALS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isError: true,
        totalItems: 0,
      }
    default:
      return state
  }
}

export default reducer
