import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import {
  getTeacherAllFailure,
  getTeacherAllSuccess,
  getTeacherFailure,
  getTeacherSuccess,
} from './actions'

export function* fetchTeacherAll(): any {
  try {
    const response = yield call(api.get, '/teachers')
    yield put(getTeacherAllSuccess(response.data))
  } catch (err) {
    yield put(getTeacherAllFailure())
  }
}

export function* fetchTeacher(data: any): any {
  try {
    const response = yield call(api.get, `/teachers/${data.payload.teacherId}`)
    yield put(getTeacherSuccess(response.data))
  } catch (err) {
    yield put(getTeacherFailure())
  }
}
