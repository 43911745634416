import { all, takeEvery } from 'redux-saga/effects'

// Type
import { types } from '.'

//Sagas
import {
  register,
  login,
  changePassword,
  activateAccount,
  sendActivationLink,
  recoveryByEmail,
  refreshUser,
  getProfile,
  updateProfile,
  updateAvatar,
  logout,
  pre_register,
  complete_account,
  delete_account,
} from './Auth/sagas'
import { searchIndex, searchHome, search } from './Search/sagas'
import {
  mySchedule,
  purchase as purchaseSchedule,
  schedule,
  payment as paymentSchedule,
  calculate_installments as calculate_installmentsSchedule,
  new_schedule_available,
  getAllSchedule,
  delete_schedule_available,
  discount_orders,
  discount_coupon,
} from './Schedule/sagas'
import { getAllReview, findReview, addReview } from './Reviews/sagas'
import {
  getListPlans,
  newSubscription,
  cancelSubscription,
  payment as paymentSubscriptions,
  purchase as purchaseSubscriptions,
  calculate_installments as calculate_installmentsSubscriptions,
  getPlan,
  discount_coupon as discount_couponSubscriptions,
} from './Subscriptions/sagas'
import { get_list_specialization } from './Specializations/sagas'
import { get_list_modalities } from './Modalities/sagas'
import { fetchTeacher, fetchTeacherAll } from './Teacher/sagas'
import {
  getAllOrders,
  acceptOrder,
  denyOrder,
  cancelOrder,
  requestRefund,
  getClassesTeacherOrders,
  cancelPendingOrder,
} from './Orders/sagas'
import { notificationOpen } from './Notification/sagas'
import { get_list_invites, send_invites } from './Invites/sagas'
import { get_all_faq } from './FAQ/sagas'
import { get_all_testimonials } from './Testimonials/sagas'
import { get_privacy_policies } from './PrivacyPolicies/sagas'
import { get_terms_of_use } from './TermsOfUse/sagas'
import { send_message } from './Contact/sagas'
import { dashboard_notification } from './DashboardNotifications/sagas'
import { list_post, list_category, get_post, get_category } from './Blog/sagas'
import { get_all_settings } from './Settings/sagas'
import { create_accesses } from './Accesses/sagas'
import { get_content } from './Contents/sagas'

export default function* rootSagas(): any {
  return yield all([
    //Schedule
    takeEvery(types.ScheduleTypes.GET_ALL_SCHEDULE_REQUEST, getAllSchedule),
    takeEvery(types.ScheduleTypes.MY_SCHEDULE_REQUEST, mySchedule),
    takeEvery(types.ScheduleTypes.SCHEDULE_REQUEST, schedule),
    takeEvery(types.ScheduleTypes.PURCHASE_REQUEST, purchaseSchedule),
    takeEvery(types.ScheduleTypes.PAYMENT_REQUEST, paymentSchedule),
    takeEvery(types.ScheduleTypes.CAlCULATE_INSTALLMENTS_REQUEST, calculate_installmentsSchedule),
    takeEvery(types.ScheduleTypes.DISCOUNTS_COUPON_REQUEST, discount_coupon),
    takeEvery(types.ScheduleTypes.DISCOUNTS_ORDERS_REQUEST, discount_orders),
    takeEvery(types.ScheduleTypes.NEW_SCHEDULE_AVAILABLE_REQUEST, new_schedule_available),
    takeEvery(types.ScheduleTypes.DELETE_SCHEDULE_AVAILABLE_REQUEST, delete_schedule_available),

    // Order
    takeEvery(types.OrdersTypes.GET_ALL_REQUEST, getAllOrders),
    takeEvery(types.OrdersTypes.ACCEPT_ORDER_REQUEST, acceptOrder),
    takeEvery(types.OrdersTypes.DENY_ORDER_REQUEST, denyOrder),
    takeEvery(types.OrdersTypes.CANCEL_ORDER_REQUEST, cancelOrder),
    takeEvery(types.OrdersTypes.REQUEST_REFUND_REQUEST, requestRefund),
    takeEvery(types.OrdersTypes.CANCEL_PENDING_ORDER_REQUEST, cancelPendingOrder),
    takeEvery(types.OrdersTypes.GET_CLASSES_TEACHER_REQUEST, getClassesTeacherOrders),

    //Teacher
    takeEvery(types.TeacherTypes.REQUEST_GET_TEACHER, fetchTeacher),
    takeEvery(types.TeacherTypes.REQUEST_GET_TEACHER_ALL, fetchTeacherAll),

    // Modalities
    takeEvery(types.ModalityTypes.LIST_MODALITY_REQUEST, get_list_modalities),

    // Subscriptions
    takeEvery(types.SubscriptionsTypes.LIST_PLANS_REQUEST, getListPlans),
    takeEvery(types.SubscriptionsTypes.GET_PLAN_REQUEST, getPlan),
    takeEvery(types.SubscriptionsTypes.NEW_SUBSCRIPTION_REQUEST, newSubscription),
    takeEvery(types.SubscriptionsTypes.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription),
    takeEvery(types.SubscriptionsTypes.PURCHASE_REQUEST, purchaseSubscriptions),
    takeEvery(types.SubscriptionsTypes.PAYMENT_REQUEST, paymentSubscriptions),
    takeEvery(
      types.SubscriptionsTypes.CAlCULATE_INSTALLMENTS_REQUEST,
      calculate_installmentsSubscriptions,
    ),
    takeEvery(types.SubscriptionsTypes.DISCOUNTS_COUPON_REQUEST, discount_couponSubscriptions),

    // Specialization
    takeEvery(types.SpecializationTypes.LIST_SPECIALIZATIONS_REQUEST, get_list_specialization),

    // Review
    takeEvery(types.ReviewTypes.REVIEW_REQUEST_GET_ALL, getAllReview),
    takeEvery(types.ReviewTypes.REVIEW_REQUEST_FIND, findReview),
    takeEvery(types.ReviewTypes.REVIEW_REQUEST_ADD, addReview),

    // Invites
    takeEvery(types.InvitesTypes.LIST_INVITES_REQUEST, get_list_invites),
    takeEvery(types.InvitesTypes.SEND_INVITES_REQUEST, send_invites),

    // Search
    takeEvery(types.SearchTypes.SEARCH_REQUEST_INDEX, searchIndex),
    takeEvery(types.SearchTypes.SEARCH_REQUEST, search),
    takeEvery(types.SearchTypes.SEARCH_HOME_REQUEST, searchHome),

    // Auth
    takeEvery(types.AuthTypes.REGISTER_REQUEST, register),
    takeEvery(types.AuthTypes.PRE_REGISTER_REQUEST, pre_register),
    takeEvery(types.AuthTypes.LOGIN_REQUEST, login),
    takeEvery(types.AuthTypes.RECOVERY_BY_EMAIL_REQUEST, recoveryByEmail),
    takeEvery(types.AuthTypes.COMPLETE_ACCOUNT_REQUEST, complete_account),
    takeEvery(types.AuthTypes.PROFILE_REQUEST, getProfile),
    takeEvery(types.AuthTypes.UPDATE_PROFILE_REQUEST, updateProfile),
    takeEvery(types.AuthTypes.UPDATE_AVATAR_REQUEST, updateAvatar),
    takeEvery(types.AuthTypes.DELETE_REQUEST, delete_account),
    takeEvery(types.AuthTypes.CHANGE_PASSWORD_REQUEST, changePassword),
    takeEvery(types.AuthTypes.REFRESH_USER, refreshUser),
    takeEvery(types.AuthTypes.ACTIVATE_ACCOUNT_REQUEST, activateAccount),
    takeEvery(types.AuthTypes.RE_SEND_ACTIVATION_LINK_REQUEST, sendActivationLink),
    takeEvery(types.AuthTypes.LOGOUT, logout),

    // Accesses
    takeEvery(types.AccessesTypes.CREATE_ACCESSES, create_accesses),

    //Notification
    takeEvery(types.NotificationTypes.NOTIFICATION_OPEN, notificationOpen),

    //FAQ
    takeEvery(types.FAQTypes.GET_ALL_FAQ_REQUEST, get_all_faq),

    //Testimonials
    takeEvery(types.TestimonialsTypes.GET_ALL_TESTIMONIALS_REQUEST, get_all_testimonials),

    //PrivacyPolicies
    takeEvery(types.PrivacyPoliciesTypes.GET_PRIVACY_POLICIES_REQUEST, get_privacy_policies),

    // TermsOfUse
    takeEvery(types.TermsOfUseTypes.GET_TERMS_OF_USE_REQUEST, get_terms_of_use),

    //Contact
    takeEvery(types.ContactTypes.SEND_MESSAGE_REQUEST, send_message),

    // Dashboard Notifications
    takeEvery(
      types.DashboardNotificationTypes.DASHBOARD_NOTIFICATION_REQUEST,
      dashboard_notification,
    ),

    // Blog
    takeEvery(types.BlogTypes.LIST_POST_REQUEST, list_post),
    takeEvery(types.BlogTypes.LIST_CATEGORY_REQUEST, list_category),
    takeEvery(types.BlogTypes.GET_POST_REQUEST, get_post),
    takeEvery(types.BlogTypes.GET_CATEGORY_REQUEST, get_category),

    //Settings
    takeEvery(types.SettingsTypes.GET_ALL_SETTINGS_REQUEST, get_all_settings),

    // Contents
    takeEvery(types.ContentsTypes.GET_CONTENT_REQUEST, get_content),
  ])
}
