import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import { getAllFAQSuccess, getAllFAQFailure } from './actions'

export function* get_all_faq(): any {
  try {
    const response = yield call(api.get, '/faqs')
    yield put(getAllFAQSuccess(response.data))
  } catch (error) {
    yield put(getAllFAQFailure(error))
  }
}
