import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import { getDashboardNotificationSuccess, getDashboardNotificationFailure } from './actions'

export function* dashboard_notification(): any {
  try {
    const response = yield call(api.get, '/dashboard_notifications')
    yield put(getDashboardNotificationSuccess(response.data))
  } catch (error) {
    yield put(getDashboardNotificationFailure(error))
  }
}
