import { call } from 'redux-saga/effects'
import api from '../../../services/api'

export function* create_accesses(action: any): any {
  try {
    yield call(api.post, `/accesses`, {
      profileType: action.payload.profileType,
    })
  } catch (error) {
    console.log(error)
  }
}
