import { Reducer } from 'redux'
import { ModalityState, ModalityTypes } from './types'

const INITIAL_STATE: ModalityState = {
  data: [],
  error: '',
  isError: false,
  isLoading: false,
  totalItems: 0,
}

const reducer: Reducer<ModalityState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModalityTypes.LIST_MODALITY_REQUEST:
      return {
        ...state,
        data: [],
        error: '',
        isLoading: true,
        isError: false,
        totalItems: 0,
      }
    case ModalityTypes.LIST_MODALITY_SUCCESS:
      return {
        ...state,
        data: action.payload.data['hydra:member'],
        error: '',
        isLoading: false,
        isError: false,
        totalItems: action.payload.data['hydra:totalItems'],
      }
    case ModalityTypes.LIST_MODALITY_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload.error,
        isLoading: false,
        isError: true,
        totalItems: 0,
      }
    default:
      return state
  }
}

export default reducer
