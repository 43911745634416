/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { createContext } from 'react'
import { useCookies } from 'react-cookie'

import { AuthProviderProps } from '../interfaces'

import { Auth, User } from '../store/ducks/Auth/types'
import Cookie from '../tools/cookie'

interface AuthContextData {
  signed: boolean
  auth: Auth | null
  user: User | null
  role: string
  logout(): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const getRoles = (roles: string[]) => {
  if (roles?.find((r: string) => r === 'ROLE_STUDENT')) return 'student'
  else if (roles?.find((r: string) => r === 'ROLE_TEACHER')) return 'teacher'
  else return ''
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ state, children }) => {
  const [cookies, _] = useCookies(['auth'])
  const [auth, setAuth] = React.useState<Auth | null>((cookies.auth as Auth) ?? null)
  const [user, setUser] = React.useState<User | null>(state.auth.auth)
  const [signed, setSigned] = React.useState<boolean>(Cookie.check('auth') ?? false)
  const [role, setRole] = React.useState<string>(getRoles((cookies.auth as Auth)?.roles))

  React.useEffect(() => {
    setAuth(cookies?.auth as Auth)
    setSigned(cookies?.auth ? true : false)
    setRole(role)
  }, [cookies])

  React.useEffect(() => {
    setUser(state.auth.user)
  }, [state.auth.user])

  const logout = () => (document.location.href = '/logout')

  const appStateProvider = React.useMemo(
    () => ({ signed, auth, user, role, logout }),
    [signed, auth, user, role],
  )

  return <AuthContext.Provider value={appStateProvider}>{children}</AuthContext.Provider>
}

export default AuthContext
