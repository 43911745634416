import { Reducer } from 'redux'
import { ReviewState, ReviewTypes } from './types'

const INITIAL_STATE: ReviewState = {
  review: null,
  reviews: [],
  loading: false,
  error: false,
  totalItems: 0,
}

const reducer: Reducer<ReviewState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReviewTypes.REVIEW_REQUEST_GET_ALL:
      return {
        ...state,
        reviews: [],
        review: null,
        loading: true,
        error: false,
        totalItems: 0,
      }
    case ReviewTypes.REVIEW_SUCCESS_GET_ALL:
      return {
        ...state,
        reviews: action.payload.data['hydra:member'],
        review: null,
        loading: false,
        error: false,
        totalItems: action.payload.data['hydra:totalItems'],
      }
    case ReviewTypes.REVIEW_FAILURE_GET_ALL:
      return {
        ...state,
        loading: false,
        error: true,
        totalItems: 0,
      }

    case ReviewTypes.REVIEW_REQUEST_FIND:
      return {
        ...state,
        review: null,
        loading: true,
        error: false,
      }
    case ReviewTypes.REVIEW_SUCCESS_FIND:
      return {
        ...state,
        reviews: action.payload.data['hydra:member'],
        loading: false,
        error: false,
        totalItems: action.payload.data['hydra:totalItems'],
      }
    case ReviewTypes.REVIEW_FAILURE_FIND:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default reducer
