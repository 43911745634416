import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import {
  getPostSuccess,
  getPostFailure,
  getCategorySuccess,
  getCategoryFailure,
  listPostSuccess,
  listPostFailure,
  listCategorySuccess,
  listCategoryFailure,
} from './actions'

export function* list_post(action: any): any {
  try {
    let url = '/posts'
    let params = Array<string>()

    if (action.payload.title && action.payload.title.length > 0)
      params.push(`title=${action.payload.title}`)
    if (action.payload.tags && action.payload.tags.length > 0)
      params.push(`tags[]=${action.payload.tags.join(',')}`)
    if (action.payload.category && action.payload.category.length > 0)
      params.push(`category.slug[]=${action.payload.category.join(',')}`)

    if (params.length > 0) url = `${url}?${params.join(',')}`

    const response = yield call(api.get, url)
    yield put(listPostSuccess(response.data))
  } catch (error) {
    console.log(error)
    yield put(listPostFailure(error))
  }
}
export function* get_post(action: any): any {
  try {
    const response = yield call(api.get, `/posts/${action.payload.id}`)
    yield put(getPostSuccess(response.data))
  } catch (error) {
    console.log(error)
    yield put(getPostFailure(error))
  }
}

export function* list_category(): any {
  try {
    const response = yield call(api.get, '/categories')
    yield put(listCategorySuccess(response.data))
  } catch (error) {
    console.log(error)
    yield put(listCategoryFailure(error))
  }
}
export function* get_category(action: any): any {
  try {
    const response = yield call(api.get, `/categories/${action.payload.id}`)
    yield put(getCategorySuccess(response.data))
  } catch (error) {
    console.log(error)
    yield put(getCategoryFailure(error))
  }
}
