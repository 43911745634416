import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import { getContentFailure, getContentSuccess } from './actions'

export function* get_content(): any {
  try {
    const response = yield call(api.get, `/contents`)
    yield put(getContentSuccess(response.data))
  } catch (error) {
    yield put(getContentFailure(error))
  }
}
