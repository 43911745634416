import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import { getAllTestimonialsSuccess, getAllTestimonialsFailure } from './actions'

export function* get_all_testimonials(data: any): any {
  try {
    const response = yield call(
      api.get,
      `/testimonials?pagination=true&itemsPerPage=${data.payload.itemsPerPage}`,
    )
    yield put(getAllTestimonialsSuccess(response.data))
  } catch (error) {
    yield put(getAllTestimonialsFailure(error))
  }
}
