/**
 * Imports Types
 */
import { AuthTypes } from './Auth/types'
import { TeacherTypes } from './Teacher/types'
import { ScheduleTypes } from './Schedule/types'
import { ReviewTypes } from './Reviews/types'
import { OrdersTypes } from './Orders/types'
import { SubscriptionsTypes } from './Subscriptions/types'
import { SpecializationTypes } from './Specializations/types'
import { SearchTypes } from './Search/types'
import { ModalityTypes } from './Modalities/types'
import { NotificationTypes } from './Notification/types'
import { FAQTypes } from './FAQ/types'
import { InvitesTypes } from './Invites/types'
import { TestimonialsTypes } from './Testimonials/types'
import { PrivacyPoliciesTypes } from './PrivacyPolicies/types'
import { TermsOfUseTypes } from './TermsOfUse/types'
import { ContactTypes } from './Contact/types'
import { DashboardNotificationTypes } from './DashboardNotifications/types'
import { BlogTypes } from './Blog/types'
import { SettingsTypes } from './Settings/types'
import { AccessesTypes } from './Accesses/types'
import { ContentsTypes } from './Contents/types'

export const types = {
  AuthTypes,
  SearchTypes,
  TeacherTypes,
  ScheduleTypes,
  ReviewTypes,
  OrdersTypes,
  SubscriptionsTypes,
  SpecializationTypes,
  ModalityTypes,
  InvitesTypes,
  NotificationTypes,
  FAQTypes,
  TestimonialsTypes,
  PrivacyPoliciesTypes,
  TermsOfUseTypes,
  ContactTypes,
  DashboardNotificationTypes,
  BlogTypes,
  SettingsTypes,
  AccessesTypes,
  ContentsTypes,
}
