import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { FacebookProvider } from 'react-facebook'
import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang'
import { CookiesProvider } from 'react-cookie'
import { PersistGate } from 'redux-persist/integration/react'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

// Supports weights 300-800
import '@fontsource-variable/open-sans/wdth-italic.css'

import App from './App'
import { store, persistor } from './store'

// Import Styles
import './styles/global.css'
import './styles/App.css'

import pt from './lang/pt_BR.json'
import en from './lang/en.json'

setDefaultTranslations({ pt, en })
setDefaultLanguage('pt')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <FacebookProvider appId='825412368501599'>
      <PersistGate loading={null} persistor={persistor}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </PersistGate>
    </FacebookProvider>
  </Provider>,
)
