import { action } from 'typesafe-actions'
import { Contact } from '../../../types/Contact'
import { Error } from '../../../types/Error'
import { types } from '../index'

export const send_message_init = () => action(types.ContactTypes.SEND_MESSAGE_INIT)

export const send_message_request = (contact: Contact) =>
  action(types.ContactTypes.SEND_MESSAGE_REQUEST, { contact })

export const send_message_success = () => action(types.ContactTypes.SEND_MESSAGE_SUCCESS)

export const send_message_failure = (error: Error) =>
  action(types.ContactTypes.SEND_MESSAGE_FAILURE, { ...error })
