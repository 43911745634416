export enum DiscountCouponType {
  /**
   * Por valor fixo
   */
  FixedAmount = 0,
  /**
   * Por porcentagem
   */
  Percent = 1,
}
