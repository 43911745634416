import { Reducer } from 'redux'
import { PrivacyPoliciesState, PrivacyPoliciesTypes } from './types'

const INITIAL_STATE: PrivacyPoliciesState = {
  data: null,
  error: null,
  isError: false,
  isLoading: false,
}

const reducer: Reducer<PrivacyPoliciesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PrivacyPoliciesTypes.GET_PRIVACY_POLICIES_REQUEST:
      return {
        ...state,
        data: null,
        error: null,
        isLoading: true,
        isError: false,
      }
    case PrivacyPoliciesTypes.GET_PRIVACY_POLICIES_SUCCESS:
      return {
        ...state,
        data: action.payload.data['hydra:member'],
        error: null,
        isLoading: false,
        isError: false,
      }
    case PrivacyPoliciesTypes.GET_PRIVACY_POLICIES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isError: true,
      }
    default:
      return state
  }
}

export default reducer
