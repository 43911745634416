import { Reducer } from 'redux'
import { ContactState, ContactTypes } from './types'

const INITIAL_STATE: ContactState = {
  data: null,
  error: null,
  isError: false,
  isLoading: false,
  validations: [],
}

const reducer: Reducer<ContactState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContactTypes.SEND_MESSAGE_INIT:
      return {
        ...INITIAL_STATE,
      }
    case ContactTypes.SEND_MESSAGE_REQUEST:
      return {
        ...state,
        data: action.payload.contact,
        error: null,
        isLoading: true,
        isError: false,
        validations: [],
      }
    case ContactTypes.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        data: null,
        error: null,
        isLoading: false,
        isError: false,
        validations: [],
      }
    case ContactTypes.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isError: true,
        validations: action.payload.violations,
      }
    default:
      return state
  }
}

export default reducer
