/* eslint-disable no-async-promise-executor */
/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import Cookies from 'universal-cookie'
import moment from 'moment'
import { Auth } from '../types/Auth'

const cookies = new Cookies()

const authCookie = cookies.get('auth') ? (cookies.get('auth') as Auth) : null

let tokenState = {
  old: authCookie?.refresh_token,
  token: authCookie?.token,
  isRefreshToken: false,
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const refreshToken = async (request) => {
  // eslint-disable-next-line no-return-await
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
      refresh_token: tokenState.old,
    })
    .then((res) => {
      cookies.set('auth', JSON.stringify(res.data), {
        path: '/',
        expires: moment().add(24, 'hours').toDate(),
      })
      tokenState.token = res.data.token
      request.headers['Authorization'] = `Bearer ${tokenState.token}`
      return request
    })
}

api.interceptors.request.use(
  async (config) => {
    if (tokenState.token) config.headers.Authorization = `Bearer ${tokenState.token}`
    if (!tokenState.isRefreshToken && tokenState.token) {
      tokenState.isRefreshToken = false
      // eslint-disable-next-line no-return-await
      return await new Promise(async (resolve) => {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/check_token?token=${tokenState.token}`, {
            headers: {
              Authorization: `Bearer ${tokenState.token}`,
            },
          })
          .then(async ({ data }) => {
            if (data.status === 'expired') {
              if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                console.info('Updated token for new configuration')
              tokenState.isRefreshToken = true
              let newConfig = await refreshToken(config)
              return resolve(newConfig)
            }
            if (data.status === 'invalidated_session') {
              if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                console.info('Token invalidated session logged out')
              window.location.href = '/logout'
              tokenState.isRefreshToken = true
              return resolve(config)
            }
            if (data.status === 'valid') {
              if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                console.info('Valid token for old configuration')
              tokenState.isRefreshToken = true
              return resolve(config)
            }
          })
          .catch(async () => {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
              console.info('Token invalidated session logged out')
            }
            window.location.href = '/logout'
            tokenState.isRefreshToken = true
            return resolve(config)
          })
          .finally(() => {
            tokenState.isRefreshToken = false
          })
      })
    }
    return config
  },
  (error) => Promise.reject(error),
)

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    return new Promise((resolve, reject) => {
      if (error.response.status === 403) {
        window.location.href = '/logout'
        return resolve(error)
      }
      return reject(error)
    })
  },
)
export default api
