import { Reducer } from 'redux'
import { AuthTypes, AuthState } from './types'

const INITIAL_STATE: AuthState = {
  auth: null,
  user: null,
  token: null,
  isLoggedIn: false,
  isError: false,
  message: '',
  validations: [],
  status: 0,
  role: '',
  loading: false,
  isAccountActivated: false,
  isRecoveryEmail: false,
}

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.REGISTER_INIT:
    case AuthTypes.PRE_REGISTER_INIT:
      return {
        ...state,
        ...INITIAL_STATE,
      }
    case AuthTypes.REGISTER_REQUEST:
    case AuthTypes.PRE_REGISTER_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        isError: false,
        loading: true,
        auth: null,
        user: null,
        validations: [],
        role: '',
      }
    case AuthTypes.REGISTER_FAIL:
    case AuthTypes.PRE_REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        isError: true,
        loading: false,
        message: action.payload?.response?.data['hydra:description'],
        validations: action.payload?.response?.data?.violations
          ? action.payload?.response?.data.violations
          : [],
        auth: null,
        user: null,
        role: '',
      }

    case AuthTypes.LOGIN_INIT: {
      return {
        ...state,
        auth: null,
        user: null,
        error: false,
        validations: [],
        loading: false,
        isLoggedIn: false,
        isError: false,
        role: '',
      }
    }
    case AuthTypes.LOGIN_REQUEST:
      return {
        ...state,
        auth: null,
        user: null,
        message: null,
        validations: [],
        loading: true,
        isLoggedIn: false,
        isError: false,
        role: '',
      }
    case AuthTypes.LOGIN_SUCCESS:
      return {
        ...state,
        auth: action.payload.auth,
        user: action.payload.user,
        message: null,
        validations: [],
        isLoggedIn: true,
        loading: false,
        isError: false,
      }
    case AuthTypes.LOGIN_FAIL:
      return {
        ...state,
        message: action.payload['hydra:description'],
        validations: action.payload?.violations ?? [],
        loading: false,
        isLoggedIn: false,
        isError: true,
        role: '',
      }

    case AuthTypes.CHANGE_PASSWORD_INIT:
      return {
        ...state,
        isError: false,
        loading: false,
        message: '',
        validations: [],
      }
    case AuthTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isError: false,
        loading: true,
        message: '',
        validations: [],
      }
    case AuthTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isError: false,
        loading: false,
        message: '',
        validations: [],
      }
    case AuthTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isError: true,
        loading: false,
        message: action.payload?.error['hydra:description'],
        validations: action.payload?.error?.violations ? action.payload?.error?.violations : [],
      }

    case AuthTypes.RECOVERY_BY_EMAIL_INIT:
      return {
        ...state,
        loading: false,
        isError: false,
        isRecoveryEmail: false,
        message: '',
        validations: [],
      }
    case AuthTypes.RECOVERY_BY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
        isRecoveryEmail: false,
        message: '',
        validations: [],
      }
    case AuthTypes.RECOVERY_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRecoveryEmail: true,
        message: '',
        validations: [],
      }
    case AuthTypes.RECOVERY_BY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        isRecoveryEmail: false,
        message: action.payload?.error['hydra:description'],
        validations: action.payload?.error?.violations ? action.payload?.error?.violations : [],
      }
    case AuthTypes.PROFILE_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
      }
    }
    case AuthTypes.UPDATE_PROFILE_REQUEST:
    case AuthTypes.COMPLETE_ACCOUNT_REQUEST:
      return {
        ...state,
        isError: false,
        loading: true,
        message: '',
        validations: [],
      }
    case AuthTypes.UPDATE_PROFILE_SUCCESS:
    case AuthTypes.COMPLETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isError: false,
        loading: false,
        message: '',
        validations: [],
      }
    case AuthTypes.UPDATE_PROFILE_FAILURE:
    case AuthTypes.COMPLETE_ACCOUNT_FAILURE:
      return {
        ...state,
        isError: true,
        loading: false,
        message: action.payload?.error['hydra:description'],
        validations: action.payload?.error?.violations ? action.payload?.error?.violations : [],
      }

    case AuthTypes.UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
      }

    case AuthTypes.GET_ROLE:
      return {
        ...state,
        role: action.payload.role,
      }

    case AuthTypes.ACTIVATE_ACCOUNT_REQUEST: {
      return {
        ...state,
        message: '',
        loading: true,
        isError: false,
        isAccountActivated: false,
      }
    }
    case AuthTypes.ACTIVATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        isError: false,
        isAccountActivated: true,
        message:
          'Sua conta foi ativada com sucesso. Faça seu login para aproveitar todas as nossas funcionalidades.',
      }
    }
    case AuthTypes.ACTIVATE_ACCOUNT_FAIL: {
      return {
        ...state,
        loading: false,
        isError: true,
        isAccountActivated: false,
        message: 'Ocorreu um error ao ativar sua conta, token expirando ou invalido!',
      }
    }

    case AuthTypes.RE_SEND_ACTIVATION_LINK_REQUEST: {
      return {
        ...state,
        loading: true,
        isError: false,
        message: '',
      }
    }
    case AuthTypes.RE_SEND_ACTIVATION_LINK_SUCCESS: {
      return {
        ...state,
        loading: false,
        isError: false,
        message: 'Foi enviando um novo token ao seu endereço de e-mail!',
      }
    }
    case AuthTypes.RE_SEND_ACTIVATION_LINK_FAIL: {
      return {
        ...state,
        loading: false,
        isError: true,
        message: 'Endereço de e-mail invalido, não foi possível re-enviar o token!',
      }
    }

    case AuthTypes.LOGOUT:
      return {
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}

export default reducer
