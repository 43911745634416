import { action } from 'typesafe-actions'
import { types } from '../index'

export const listPostRequest = (title?: string, tags?: string[], category?: string[]) =>
  action(types.BlogTypes.LIST_POST_REQUEST, {
    title,
    tags,
    category,
  })
export const listPostSuccess = (data: any) => action(types.BlogTypes.LIST_POST_SUCCESS, { data })
export const listPostFailure = (error: any) => action(types.BlogTypes.LIST_POST_FAILURE, { error })

export const getPostRequest = (id: string) => action(types.BlogTypes.GET_POST_REQUEST, { id })
export const getPostSuccess = (post: any) => action(types.BlogTypes.GET_POST_SUCCESS, { post })
export const getPostFailure = (error: any) => action(types.BlogTypes.GET_POST_FAILURE, { error })

export const listCategoryRequest = () => action(types.BlogTypes.LIST_CATEGORY_REQUEST)
export const listCategorySuccess = (data: any) =>
  action(types.BlogTypes.LIST_CATEGORY_SUCCESS, { data })
export const listCategoryFailure = (error: any) =>
  action(types.BlogTypes.LIST_CATEGORY_FAILURE, { error })

export const getCategoryRequest = (id: string) =>
  action(types.BlogTypes.GET_CATEGORY_REQUEST, { id })
export const getCategorySuccess = (data: any) =>
  action(types.BlogTypes.GET_CATEGORY_SUCCESS, { data })
export const getCategoryFailure = (error: any) =>
  action(types.BlogTypes.GET_CATEGORY_FAILURE, { error })
