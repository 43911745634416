import { Reducer } from 'redux'
import { DashboardNotificationState, DashboardNotificationTypes } from './types'

const INITIAL_STATE: DashboardNotificationState = {
  dataAll: [],
  error: null,
  isError: false,
  isLoading: false,
}

const reducer: Reducer<DashboardNotificationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashboardNotificationTypes.DASHBOARD_NOTIFICATION_REQUEST:
      return {
        ...state,
        dataAll: [],
        error: null,
        isLoading: true,
        isError: false,
      }
    case DashboardNotificationTypes.DASHBOARD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        dataAll: action.payload.data['hydra:member'],
        error: null,
        isLoading: false,
        isError: false,
      }
    case DashboardNotificationTypes.DASHBOARD_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isError: true,
      }
    default:
      return state
  }
}

export default reducer
