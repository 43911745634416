import { Order as OrderType } from '../../../types/Order'
import { Page } from '../../../types/Page'
import { cancelPendingOrderRequest } from './actions'

/**
 * Action Types
 */
export enum OrdersTypes {
  NEW_ORDER_REQUEST = '@order/new_order/request',
  NEW_ORDER_SUCCESS = '@order/new_order/success',
  NEW_ORDER_FAILURE = '@order/new_order/failure',

  GET_ALL_INIT = '@order/get_all/init',
  GET_ALL_REQUEST = '@order/get_all/request',
  GET_ALL_SUCCESS = '@order/get_all/success',
  GET_ALL_FAILURE = '@order/get_all/failure',

  GET_CLASSES_TEACHER_REQUEST = '@order/get_classes_teacher/request',
  GET_CLASSES_TEACHER_SUCCESS = '@order/get_classes_teacher/success',
  GET_CLASSES_TEACHER_FAILURE = '@order/get_classes_teacher/failure',

  ACCEPT_ORDER_REQUEST = '@order/accept/request',
  ACCEPT_ORDER_SUCCESS = '@order/accept/success',
  ACCEPT_ORDER_FAILURE = '@order/accept/failure',

  DENY_ORDER_REQUEST = '@order/deny/request',
  DENY_ORDER_SUCCESS = '@order/deny/success',
  DENY_ORDER_FAILURE = '@order/deny/failure',

  CANCEL_ORDER_REQUEST = '@order/cancel/request',
  CANCEL_ORDER_SUCCESS = '@order/cancel/success',
  CANCEL_ORDER_FAILURE = '@order/cancel/failure',

  CANCEL_PENDING_ORDER_REQUEST = '@order/pending_cancel/request',
  CANCEL_PENDING_ORDER_SUCCESS = '@order/pending_cancel/success',
  CANCEL_PENDING_ORDER_FAILURE = '@order/pending_cancel/failure',

  REQUEST_REFUND_REQUEST = '@order/request_refund/request',
  REQUEST_REFUND_SUCCESS = '@order/request_refund/success',
  REQUEST_REFUND_FAILURE = '@order/request_refund/failure',
}

/**
 * Data Types
 */
export interface Order extends OrderType {}

export interface ManageOrder {
  accept?: {
    orderId?: string
    order?: Order
  }
  deny?: {
    orderId: string
    order?: Order
  }
  cancel?: {
    orderId: string
    order?: Order
  }
  cancel_pending?: {
    orderId: string
    order?: Order
  }
  requestRefund?: {
    orderId: string
  }
}

/**
 * State type
 */
export interface OrdersState {
  readonly dataAll: Order[]
  readonly data: Order

  readonly dataAvailable: string
  readonly manageOrder: ManageOrder

  readonly page: Page
  readonly totalItems: number

  readonly loading: boolean

  readonly isSuccess: boolean
  readonly isError: boolean
  readonly error: string
}
