import { Moment } from 'moment'
import { action } from 'typesafe-actions'
import { CreditCard } from '../../../types/CreditCard'
import { Error } from '../../../types/Error'
import { Order } from '../Orders/types'
import { Teacher } from '../Teacher/types'
import { Schedule, ScheduleTypes } from './types'
import { Transaction } from '../../../types/Transaction'
import { ScheduleAvailable } from '../../../types/ScheduleAvailable'
import { MySchedule, MyScheduleFilter } from '../../../types/MySchedule'
import { DiscountCoupon } from '../../../types/DiscountCoupon'

/**
 ############### Get All Schedule ###############
*/
export const getAllScheduleInit = () => action(ScheduleTypes.GET_ALL_SCHEDULE_INIT)

/**
 * It returns an action object with a type of
 * `ScheduleTypes.GET_ALL_SCHEDULE_REQUEST`
 */
export const getAllScheduleRequest = () => action(ScheduleTypes.GET_ALL_SCHEDULE_REQUEST)

/**
 * It returns an action object with the type of GET_ALL_SCHEDULE_SUCCESS and the
 * schedule array
 * @param {Schedule[]} schedule - Schedule[]
 */
export const getAllScheduleSuccess = (schedule: Schedule[]) =>
  action(ScheduleTypes.GET_ALL_SCHEDULE_SUCCESS, {
    schedule,
  })

/**
 * It returns an action with the type GET_ALL_SCHEDULE_FAILURE and the error that
 * was passed in
 * @param {any} error - any
 */
export const getAllScheduleFailure = (error: any) =>
  action(ScheduleTypes.GET_ALL_SCHEDULE_FAILURE, { error })

/**
 ############### MySchedule ###############
*/

export const myScheduleInit = () => action(ScheduleTypes.MY_SCHEDULE_INIT)

export const myScheduleRequest = (start: string, end: string) =>
  action(ScheduleTypes.MY_SCHEDULE_REQUEST, { start, end })

export const myScheduleSuccess = (mySchedule: MySchedule[]) =>
  action(ScheduleTypes.MY_SCHEDULE_SUCCESS, {
    mySchedule,
  })

export const myScheduleFailure = (error: any) =>
  action(ScheduleTypes.MY_SCHEDULE_FAILURE, { error })

/**
 ############### Schedule ###############
*/
export const scheduleInit = () => action(ScheduleTypes.SCHEDULE_INIT)

/**
 * It returns an action object with the type `ScheduleTypes.SCHEDULE_REQUEST` and
 * the payload of the teacher, scheduleId, and dateTimeAvailable
 * @param {Teacher} teacher - Teacher - This is the teacher that the student is
 * scheduling a lesson with.
 * @param {string} scheduleId - The id of the schedule you want to book.
 * @param {Moment} dateTimeAvailable - Moment
 */
export const schedule = (teacher: Teacher, scheduleId: string, dateTimeAvailable: Moment) =>
  action(ScheduleTypes.SCHEDULE_REQUEST, {
    teacher,
    scheduleId,
    dateTimeAvailable,
  })

/**
 ############### Purchase ###############
*/

/**
 * It returns an action object with the type property set to
 * ScheduleTypes.PURCHASE_REQUEST
 */
export const purchaseRequest = () => action(ScheduleTypes.PURCHASE_REQUEST)

/**
 * It returns an action object with the type of PURCHASE_SUCCESS and the schedule
 * object as the payload
 * @param {Schedule} schedule - Schedule - This is the schedule that was purchased.
 */
export const purchaseSuccess = (schedule: Schedule) =>
  action(ScheduleTypes.PURCHASE_SUCCESS, { schedule })

/**
 * It returns an action object with the type of PURCHASE_FAILURE and the error
 * object
 * @param {any} error - any
 */
export const purchaseFailure = (error: any) => action(ScheduleTypes.PURCHASE_FAILURE, { ...error })

/**
 ############### Payment ###############
*/
/**
 * It returns an action object with a type of `ScheduleTypes.PAYMENT_REQUEST`
 */
export const paymentRequest = (
  scheduleId: string,
  paymentMethod: string,
  creditCard: CreditCard,
  discountOrders: string[] = [],
  discountCoupon: string = null,
) =>
  action(ScheduleTypes.PAYMENT_REQUEST, {
    scheduleId,
    paymentMethod,
    creditCard,
    discountOrders,
    discountCoupon,
  })

/**
 * It returns an action object with a type of `ScheduleTypes.PAYMENT_SUCCESS` and a
 * payload of `{ order }`
 * @param {Order} order - Order
 */
export const paymentSuccess = (order: Order) => action(ScheduleTypes.PAYMENT_SUCCESS, { order })

/**
 * It returns an action object with the type of ScheduleTypes.PAYMENT_FAILURE and
 * the error object.
 * @param {any} error - any
 */
export const paymentFailure = (error: Error) => action(ScheduleTypes.PAYMENT_FAILURE, { error })

export const paymentTryAgain = () => action(ScheduleTypes.PAYMENT_TRY_AGAIN)

export const paymentWaiting = (order: Order) =>
  action(ScheduleTypes.PAYMENT_WAITING, {
    order,
  })

/**
 ############### Transaction ###############
*/

/**
 * It returns an action object with the type
 * `ScheduleTypes.PAYMENT_TRANSACTION_SUCCESS` and the transaction object as the
 * payload
 * @param {Transaction} transaction - The transaction object that was created.
 */
export const transactionSuccess = (transaction: Transaction) =>
  action(ScheduleTypes.PAYMENT_TRANSACTION_SUCCESS, { transaction })

/**
 * It returns an action object with the type of `PAYMENT_TRANSACTION_FAILURE` and
 * the transaction object as the payload
 * @param {Transaction} transaction - The transaction object that was attempted.
 */
export const transactionFailure = (transaction: Transaction) =>
  action(ScheduleTypes.PAYMENT_TRANSACTION_FAILURE, { transaction })

/**
 ############### Calculate Installments ###############
*/
/**
 * It returns an action object with the type `CALCULATE_INSTALLMENTS_REQUEST` and
 * the payload `{ amount }`
 * @param {number} amount - number
 */
export const calculateInstallmentsRequest = (amount: number) =>
  action(ScheduleTypes.CAlCULATE_INSTALLMENTS_REQUEST, { amount })

/**
 * It returns an action object with the type `CALCULATE_INSTALLMENTS_SUCCESS` and
 * the payload `{ amount, installments }`
 * @param {number} amount - number - The amount of the purchase
 * @param {number} installments - number - The number of installments
 */
export const calculateInstallmentsSuccess = (amount: number, installments: number) =>
  action(ScheduleTypes.CAlCULATE_INSTALLMENTS_SUCCESS, { amount, installments })

/**
 * It returns an action object with the type `CALCULATE_INSTALLMENTS_FAILURE` and
 * the error object
 * @param {any} error - any
 */
export const calculateInstallmentsFailure = (error: any) =>
  action(ScheduleTypes.CAlCULATE_INSTALLMENTS_FAILURE, { ...error })

/**
 ############### New Schedule Available ###############
*/

/**
 * It returns an action object with a type of NEW_SCHEDULE_AVAILABLE_INIT
 */
export const newScheduleAvailableInit = () => action(ScheduleTypes.NEW_SCHEDULE_AVAILABLE_INIT)

/**
 * It returns an action object with the type NEW_SCHEDULE_AVAILABLE_REQUEST and the
 * payload of the dateStart and dateEnd
 * @param {string} dateStart - The start date of the schedule
 * @param {string} dateEnd - string - The end date of the schedule
 */
export const newScheduleAvailableRequest = (
  scheduleAvailable: ScheduleAvailable[],
  mySchedule?: MyScheduleFilter,
) =>
  action(ScheduleTypes.NEW_SCHEDULE_AVAILABLE_REQUEST, {
    scheduleAvailable,
    mySchedule,
  })

/**
 * It returns an action object with the type NEW_SCHEDULE_AVAILABLE_SUCCESS and the
 * schedule object
 * @param {Schedule} schedule - Schedule - This is the type of the payload.
 */
export const newScheduleAvailableSuccess = () =>
  action(ScheduleTypes.NEW_SCHEDULE_AVAILABLE_SUCCESS)

/**
 * It creates an action with the type NEW_SCHEDULE_AVAILABLE_FAILURE and the error.
 * @param {any} error - any
 */
export const newScheduleAvailableFailure = (error: Error) =>
  action(ScheduleTypes.NEW_SCHEDULE_AVAILABLE_FAILURE, {
    error,
  })

/**
 ############### Delete Schedule ###############
*/
/**
 * It returns an action object with a type of `DELETE_SCHEDULE_AVAILABLE_REQUEST`
 * and a payload of `{ scheduleId }`
 * @param {string} scheduleId - string
 */
export const deleteScheduleRequest = (scheduleId: string, mySchedule?: MyScheduleFilter) =>
  action(ScheduleTypes.DELETE_SCHEDULE_AVAILABLE_REQUEST, { scheduleId, mySchedule })

/**
 * It returns an action object with a type of DELETE_SCHEDULE_AVAILABLE_SUCCESS
 */
export const deleteScheduleSuccess = () => action(ScheduleTypes.DELETE_SCHEDULE_AVAILABLE_SUCCESS)

/**
 * It returns an action object with the type of `DELETE_SCHEDULE_AVAILABLE_FAILURE`
 * and the error object
 * @param {Error} error - Error - The error that was thrown
 */
export const deleteScheduleFailure = (error: Error) =>
  action(ScheduleTypes.DELETE_SCHEDULE_AVAILABLE_FAILURE, { error })

/**
 ############### Discounts Orders ###############
*/
/**
 * It returns an action object with a type of `ScheduleTypes.DISCOUNTS_ORDERS_INIT`
 * and a payload of `{ availableCredit }`
 * @param {number} availableCredit - number
 */
export const discountOrdersInit = (availableCredit: number, classValue: number) =>
  action(ScheduleTypes.DISCOUNTS_ORDERS_INIT, { availableCredit, classValue })

/**
 * It returns an action object with a type of DISCOUNTS_ORDERS_REQUEST and a
 * payload of an object with two properties: availableCredit and classValue
 * @param {number} availableCredit - The amount of credit the user has available to
 * use.
 * @param {number} classValue - The class value of the student.
 */
export const discountOrdersRequest = (availableCredit: number, classValue: number) =>
  action(ScheduleTypes.DISCOUNTS_ORDERS_REQUEST, { availableCredit, classValue })

/**
 * It returns an action with the type `ScheduleTypes.DISCOUNTS_ORDERS_SUCCESS` and
 * the payload of `{ discountOrders, orderValue, availableCredit }`
 * @param {string[]} discountOrders - An array of order IDs that are eligible for a
 * discount.
 * @param {number} orderValue - The total value of the order.
 * @param {number} availableCredit - number - The amount of credit available to the
 * user.
 */
export const discountOrdersSuccess = (
  discountOrders: string[],
  orderValue: number,
  availableCredit: number,
) =>
  action(ScheduleTypes.DISCOUNTS_ORDERS_SUCCESS, {
    discountOrders,
    orderValue,
    availableCredit,
  })

/**
 * It returns an action object with a type of
 * `ScheduleTypes.DISCOUNTS_ORDERS_FAILURE` and an error object
 * @param {Error} error - Error - this is the error object that is returned from
 * the API.
 */
export const discountOrdersFailure = (error: Error) =>
  action(ScheduleTypes.DISCOUNTS_ORDERS_FAILURE, {
    error,
  })

/**
 ############### Discounts Coupon ###############
*/
export const discountCouponInit = (coupon: string, classValue: number) =>
  action(ScheduleTypes.DISCOUNTS_COUPON_INIT, { coupon, classValue })

/**
 * It returns an action object with the type
 * `ScheduleTypes.DISCOUNTS_COUPON_REQUEST` and the payload `{ coupon }`
 * @param {string} coupon - string - the coupon code
 */
export const discountCouponRequest = (coupon: string, classValue: number) =>
  action(ScheduleTypes.DISCOUNTS_COUPON_REQUEST, { coupon, classValue })

/**
 * It returns an action object with a type of
 * `ScheduleTypes.DISCOUNTS_COUPON_SUCCESS`
 */
export const discountCouponSuccess = (discountCoupon: DiscountCoupon, orderValue: number) =>
  action(ScheduleTypes.DISCOUNTS_COUPON_SUCCESS, { discountCoupon, orderValue })

/**
 * It returns an action object with the type
 * `ScheduleTypes.DISCOUNTS_COUPON_FAILURE` and the error object
 * @param {Error} error - Error - The error object that was thrown
 */
export const discountCouponFailure = (error: Error) =>
  action(ScheduleTypes.DISCOUNTS_COUPON_FAILURE, { error })
