import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'

import { getListSpecializationSuccess, getListSpecializationFailure } from './actions'

export function* get_list_specialization(): any {
  try {
    const response = yield call(api.get, '/specializations')
    yield put(getListSpecializationSuccess(response.data))
  } catch (error) {
    yield put(getListSpecializationFailure(error))
  }
}
