import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import { getAllSettingsSuccess, getAllSettingsFailure } from './actions'

export function* get_all_settings(): any {
  try {
    const response = yield call(api.get, `/settings`)
    yield put(getAllSettingsSuccess(response.data['hydra:member']))
  } catch (error) {
    yield put(getAllSettingsFailure(error))
  }
}
