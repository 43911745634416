import { AlertColor } from '@mui/lab'
import { SnackbarOrigin } from '@mui/material'
import { action } from 'typesafe-actions'
import { types } from '../index'

export const notificationOpenRequest = (
  title: string,
  message: string,
  severity?: AlertColor,
  autoHideDuration?: number,
  anchorOrigin: SnackbarOrigin = { horizontal: 'center', vertical: 'top' },
  variant: 'standard' | 'filled' | 'outlined' = 'filled',
) =>
  action(types.NotificationTypes.NOTIFICATION_OPEN, {
    title,
    message,
    severity,
    autoHideDuration,
    anchorOrigin,
    variant,
  })

export const notificationCloseRequest = () => action(types.NotificationTypes.NOTIFICATION_CLOSE)
