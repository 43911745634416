import axios, { AxiosError } from 'axios'
import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import { Error } from '../../../types/Error'
import { notificationOpenRequest } from '../Notification/actions'
import {
  getListInvitesRequest,
  getListInvitesFailure,
  getListInvitesSuccess,
  sendInvitesSuccess,
  sendInvitesFailure,
} from './actions'

export function* get_list_invites(): any {
  try {
    const response = yield call(api.get, '/invites')
    yield put(getListInvitesSuccess(response.data))
  } catch (error) {
    yield put(getListInvitesFailure(error))
  }
}

export function* send_invites(data: any): any {
  try {
    const response = yield call(api.post, '/invites', {
      ...data.payload.invite,
    })
    yield put(sendInvitesSuccess())
    yield put(getListInvitesRequest())
    yield put(
      notificationOpenRequest(
        'Informação',
        `Você convidou ${response.data.recipient} com sucesso`,
        'success',
        3000,
      ),
    )
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<Error>
      if (error && error.response) {
        if (error?.response?.status === 403) {
          yield put(notificationOpenRequest('Error', 'Você já convidou essa pessoa', 'error', 3000))
          yield put(
            sendInvitesFailure({
              'hydra:title': 'Error',
              'hydra:description': 'Você já convidou essa pessoa',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else if (error?.response?.status === 404) {
          yield put(
            sendInvitesFailure({
              'hydra:title': 'Error',
              'hydra:description': 'Error ao convidar essa pessoa.',
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        } else {
          yield put(
            sendInvitesFailure({
              'hydra:title': error?.response?.data?.['hydra:title'],
              'hydra:description': error?.response?.data?.['hydra:description'],
              status: error?.response?.status,
              violations: error?.response?.data?.violations,
            }),
          )
        }
      }
    }
    console.error(e)
  }
}
