import { Reducer } from 'redux'
import { SearchTypes, SearchState } from './types'

const INITIAL_STATE: SearchState = {
  dataAll: [],
  calendar: [],
  hours: [],
  specifications: [],
  native: false,
  resident: false,
  classValues: null,
  countProfessionalsFound: 0,
  isLoading: false,
  page: null,
  totalItems: 0,
}

const reducer: Reducer<SearchState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SearchTypes.SEARCH_REQUEST_INDEX:
      return {
        ...state,
        search: null,
        calendar: [],
        hours: [],
        specifications: [],
        native: false,
        resident: false,
        classValues: null,
        countProfessionalsFound: 0,
        isLoading: true,
      }
    case SearchTypes.SEARCH_SUCCESS_INDEX:
      return {
        ...state,
        dataAll: action.payload.dataAll,
        classValues: action.payload.classValues,
        countProfessionalsFound: action.payload.countProfessionalsFound ?? 0,
        page: action.payload.page,
        totalItems: action.payload.countProfessionalsFound ?? 0,
        isLoading: false,
      }
    case SearchTypes.SEARCH_FAILURE_INDEX:
      return {
        ...state,
        search: null,
        calendar: [],
        hours: [],
        specifications: [],
        native: false,
        resident: false,
        classValues: null,
        countProfessionalsFound: 0,
        isLoading: false,
      }

    case SearchTypes.SEARCH_REQUEST:
      return {
        ...state,
        calendar: action.payload.calendar,
        hours: action.payload.hours,
        specifications: action.payload.specifications,
        native: action.payload.native,
        resident: action.payload.resident,
        isLoading: true,
      }
    case SearchTypes.SEARCH_SUCCESS:
      return {
        ...state,
        dataAll: action.payload.dataAll,
        classValues: action.payload.classValues,
        countProfessionalsFound: action.payload.countProfessionalsFound,
        page: action.payload.page,
        totalItems: action.payload.countProfessionalsFound ?? 0,
        isLoading: false,
      }
    case SearchTypes.SEARCH_FAILURE:
      return {
        ...state,
        calendar: [],
        hours: [],
        specifications: [],
        native: false,
        resident: false,
        classValues: null,
        isLoading: false,
        page: null,
        totalItems: 0,
      }
    default:
      return state
  }
}

export default reducer
